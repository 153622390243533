import React, { useEffect, useState } from "react";
import Icon from "../../Icon";
import styles from "./UpArrow.module.scss";
import cn from "classnames";

export const UpArrow = () => {
  const [showing, setShowing] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setShowing(position > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = () => {
    // 부드럽게 0.0으로
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={cn(styles.up_arrow, { [styles.showing]: showing })}>
      <button onClick={handleClick}>
        <img src={require("../../../assets/images/homepage/arrow-up.png")} alt="up_arrow" />
      </button>
    </div>
  );
};
