import React, { useEffect, useState, useRef } from "react";
import styles from "./index.module.scss";
import cn from "classnames";
import { LinkButton } from "../../../../../elements/linkbutton";

import { clearAllBodyScrollLocks } from "body-scroll-lock";

export const EcoCard = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, []);

  return (
    <div className={cn(styles.card, props.className, { [styles.reverse_color]: props.data.reverseColor })} ref={props.data.ref}>
      <div className={styles.title_box}>{props.data.title}</div>
      <div className={styles.contents_box}>{props.data.contents}</div>
      {props.data.social ? (
        <div className={styles.social_box}>
          {props.data.social.map((social, index) => (
            <LinkButton className={styles.social} key={index} link={social.link}>
              <img src={social.src} alt="social" />
            </LinkButton>
          ))}
        </div>
      ) : (
        <div className={styles.button_box}>
          <LinkButton className={cn(styles.button, { [styles.disabled]: props.data.disabled })} link={props.data.link}>
            {props.data.button}
          </LinkButton>
        </div>
      )}
    </div>
  );
};
