import React, { useEffect } from "react";
import styles from "./index.module.scss";
import cn from "classnames";

import { clearAllBodyScrollLocks } from "body-scroll-lock";

export const Screenshot = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, []);

  return (
    <section className={styles.section}>
      <div className={cn("container", styles.container)}>
        <div className={styles.title_box}>Screenshots</div>
        <div className={styles.content_box}>
          <img src={require("../../../../../assets/images/homepage/normaland_screenshot.png")} alt="screenshot" />
        </div>
      </div>
    </section>
  );
};
