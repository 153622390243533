import { createContext, useContext } from 'react';

export const FavorletContext = createContext({
    connectWallet: () => { },
    disconnectWallet: () => { },
    selectedAccount: "",
    loading: false,
});

export function useFavorlet() {
    return useContext(FavorletContext);
}
