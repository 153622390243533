import React, { useEffect, useRef } from "react";
import styles from "./index.module.scss";
import cn from "classnames";
import Slider from "react-slick";
import { LinkButton } from "../../../../elements/linkbutton";

import { clearAllBodyScrollLocks } from "body-scroll-lock";

const getCenterPadding = (windowWidth) => {
  if (windowWidth >= 1000) {
    return Math.floor(windowWidth * 0.25) + "px";
  } else if (windowWidth >= 800) {
    return Math.floor(windowWidth * 0.2) + "px";
  } else if (windowWidth >= 600) {
    return Math.floor(windowWidth * 0.15) + "px";
  } else if (windowWidth >= 400) {
    return Math.floor(windowWidth * 0.1) + "px";
  } else {
    return Math.floor(windowWidth * 0.05) + "px";
  }
};

export const Nfts = () => {
  const nfts = [
    {
      src: require("../../../../../assets/images/homepage/nft1.png"),
    },
    {
      src: require("../../../../../assets/images/homepage/nft2.png"),
    },
    {
      src: require("../../../../../assets/images/homepage/nft3.png"),
    },
    {
      src: require("../../../../../assets/images/homepage/nft4.png"),
    },
    {
      src: require("../../../../../assets/images/homepage/nft5.png"),
    },
    {
      src: require("../../../../../assets/images/homepage/nft6.png"),
    },
    {
      src: require("../../../../../assets/images/homepage/nft7.png"),
    },
    {
      src: require("../../../../../assets/images/homepage/nft8.png"),
    },
  ];

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "200px",
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 1179,
        settings: {
          slidesToShow: 1,
          centerPadding: getCenterPadding(window.innerWidth),
        },
      },
    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, []);

  return (
    <section className={styles.section}>
      <div className={cn("container", styles.container)}>
        <div className={styles.title_box}>Norma NFTs</div>
        <div className={styles.subtitle_box}>Genesis NFT of the $GRAM Ecosystem</div>
        <div className={cn("slider-container", "nft_slider", styles.nft_box)}>
          <Slider {...settings}>
            {nfts.map((nft, index) => (
              <div className={styles.nft_card} key={index}>
                <img className={styles.nft} src={nft.src} alt="nft" />
              </div>
            ))}
          </Slider>
        </div>
        <div className={styles.contents_box}>
          The 10,000 PFP Norma NFTs, themed around Chef Norma,
          <br />
          the charming heroine of Norma in Metaland: Cooking Adventure,
          <br />
          will provide various buffs in-game,
          <br />
          as well as serve as proof of membership for future services with partners.
        </div>
        <div className={styles.button_box}>
          <LinkButton className={styles.button} link="https://iskra.world/market?games=2vPHloVLV1USq3Vu9RN3">
            BUY
          </LinkButton>
          <LinkButton className={styles.button} link="https://opensea.io/collection/normainmetaland">
            <img src={require("../../../../../assets/images/homepage/opensea_button.png")} alt="opensea_button" />
          </LinkButton>
        </div>
      </div>
    </section>
  );
};
