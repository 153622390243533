import { useEffect, useState } from "react";

import { MetamaskContext } from "../hooks/useMetamask";
import { isMobile } from "../../../../utility/helpers";

export const MetamaskProvider = ({ children }) => {
  const [selectedAccount, setSelectedAccount] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isReturningUser, setIsReturningUser] = useState(false);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("connect", () => {
        window.ethereum.request({ method: "eth_accounts" }).then((addr) => {
          if (addr.length > 0) {
            connectWallet();
          }
        });
      });
      //    window.ethereum.on('disconnect', disconnectWallet)
      window.ethereum.on("accountsChanged", (addr) => {
        if (addr.length == 0) {
          // window.location.reload();
        } else {
          connectWallet();
        }
      });
      //window.ethereum.on('chainChanged', () => window.location.reload())
    }
    // eslint-disable-next-line
  });

  const connectWallet = async () => {
    try {
      if (isMobile) {
        if (!window.ethereum || !window.ethereum.isMetaMask) {
          const address = window.location.href.split("://")[1];
          window.location.href = `https://metamask.app.link/dapp/${address}`;
          return;
        }
      } else if (typeof window.ethereum === "undefined") {
        window.open("https://metamask.io/download.html", "_blank");
        return;
      }

      setLoading(true);

      // Finally, request the account
      const walletAddress = await window.ethereum.request({
        method: "eth_requestAccounts",
        params: [
          {
            eth_accounts: {},
          },
        ],
      });

      if (isReturningUser) {
        await window.ethereum.request({
          method: "wallet_requestPermissions",
          params: [
            {
              eth_accounts: {},
            },
          ],
        });
        setIsReturningUser(false);
      }

      accountChangedHandler(walletAddress[0]);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const disconnectWallet = () => {
    setSelectedAccount("");
    setIsReturningUser(true);
  };

  const accountChangedHandler = (newAccount) => {
    setSelectedAccount(newAccount);
  };

  return (
    <MetamaskContext.Provider
      value={{
        connectWallet,
        disconnectWallet,
        selectedAccount,
        error,
        loading,
      }}
    >
      {children}
    </MetamaskContext.Provider>
  );
};
