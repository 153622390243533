import React, { useState } from "react";
import styles from "./index.module.scss";
import cn from "classnames";
import { LinkButton } from "../../../../elements/linkbutton";
import YouTube from "@u-wave/react-youtube";

import { ReactComponent as VolumnOn } from "../../../../../assets/images/homepage/volume_on.svg";
import { ReactComponent as VolumnOff } from "../../../../../assets/images/homepage/volume_off.svg";
export const Movie = () => {
  const [muted, setMuted] = useState(true);

  const toggleMute = () => {
    setMuted(!muted);
  };

  const onEnd = (event) => {
    event.target.playVideo();
  };

  return (
    <section className={styles.section}>
      <div className={cn("container", styles.container)}>
        <YouTube className={styles.video} autoplay={true} muted={muted} loop={true} controls={false} showCaptions={false} disableKeyboard={true} showInfo={false} onEnd={onEnd} playsInline={true} video="xP2OaRyw1pY" />
        <div className={styles.dim_box} />

        <div className={styles.title_box}>JUICY ADVENTURE</div>
        <div className={styles.subtitle_box}>
          A casual third-person shooter
          <br />
          and Web3 mobile game
          <br />
          that is completely free-to-play.
          <br />
          Go head-to-head
          <br />
          against other players or team up
          <br />
          with your squad in 5v5 matches.
        </div>
        <div className={styles.button_box}>
          <LinkButton className={cn(styles.button, styles.disabled)} link="/">
            Coming soon!
          </LinkButton>
        </div>
        <div className={styles.mute_container}>
          <button className={styles.mute_button} onClick={() => toggleMute()}>
            {muted ? (
              <>
                <VolumnOff name="volume" size="24" /> <span>BGM OFF</span>
              </>
            ) : (
              <>
                <VolumnOn name="volume" size="24" />
                <span>BGM ON</span>
              </>
            )}
          </button>
        </div>
      </div>
    </section>
  );
};
