import React, { useState } from "react";
import styles from "./index.module.scss";
import cn from "classnames";
import Slider from "react-slick";

export const Screenshot = () => {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
  };

  const imageList = ["https://d3u2pnu58u70ht.cloudfront.net/Announcement/jungle.gif", "https://d3u2pnu58u70ht.cloudfront.net/Announcement/historic.gif"];

  return (
    <section className={styles.section}>
      <div className={cn("container", styles.container)}>
        <div className={styles.title_box}>Screenshots</div>
        <div className={cn("slider-container", "screenshot_slider", styles.screenshot_box)}>
          <Slider {...settings}>
            {imageList.map((image, index) => (
              <div className={styles.screenshot_card} key={index}>
                <img className={styles.screenshot} src={image} alt="screenshot" />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};
