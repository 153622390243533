import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { KaikasContext } from "../hooks/useKaikas";
import { isMobile } from "../../../../utility/helpers";

export function KaikasProvider({ children }) {
  const [selectedAccount, setSelectedAccount] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (window.klaytn) {
      window.klaytn.on("accountsChanged", (addr) => {
        console.log(addr);
        if (addr.length == 0) {
          window.location.reload();
        } else {
          connectWallet();
        }
      });
    }
  }, []);

  const connectWallet = async (showErrorModal) => {
    try {
      if (isMobile) {
        showErrorModal("Kaikas wallet does not support mobile. Please use it with a PC.");
        return;
      } else if (!window.klaytn) {
        window.open("https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi", "_blank");
        return;
      }

      setLoading(true);
      await window.klaytn.enable();
      const provider = new Web3(window.klaytn);

      const accounts = await provider.eth.getAccounts();
      setSelectedAccount(accounts[0]);
      setLoading(false);
    } catch (error) {
      console.error("Failed to connect wallet:", error);
      setLoading(false);
    }
  };

  const disconnectWallet = () => {
    setSelectedAccount("");
  };

  return (
    <KaikasContext.Provider
      value={{
        selectedAccount,
        loading,
        connectWallet,
        disconnectWallet,
      }}
    >
      {children}
    </KaikasContext.Provider>
  );
}
