import React, { useEffect, useState } from "react";
import { Request, createDeepLink, receipt } from "favorlet.js";
import { FavorletContext } from "../hooks/useFavorlet";
import { isMobile } from "../../../../utility/helpers";

export function FavorletProvider({ children }) {
  const [selectedAccount, setSelectedAccount] = useState("");
  const [loading, setLoading] = useState(false);

  const connectWallet = async (showQrModal, showErrorModal) => {
    try {
      setLoading(true);

      const response = await Request.connectWallet({
        chainId: process.env.REACT_APP_CHAIN_ID, // 해당 체인 id
        appName: "Norma in Metaland", // FAVORLET 앱에 노출
      });

      const deepLink = createDeepLink(response.requestId);
      if (isMobile) {
        window.location.href = deepLink;
        showQrModal("", () => qrCallback(response.requestId, showErrorModal));
      } else {
        showQrModal(deepLink, () => qrCallback(response.requestId, showErrorModal));
      }
    } catch (error) {
      console.error("Failed to connect wallet:", error);
      setLoading(false);
    }
  };

  const qrCallback = async (requestId, showErrorModal) => {
    const result = await receipt(requestId); // Request를 통해 받은 requestId

    const wallet = result.connectWallet;
    if (wallet.status === "succeed") {
      setSelectedAccount(wallet.address);
    } else if (wallet.status === "requested") {
      showErrorModal("The wallet connection did not work properly.");
    } else if (wallet.errorMessage) {
      showErrorModal(wallet.errorMessage);
    } else {
      showErrorModal("An unknown error has occurred. Please try again.");
    }

    setLoading(false);
  };

  const disconnectWallet = () => {
    setSelectedAccount("");
  };

  return (
    <FavorletContext.Provider
      value={{
        selectedAccount,
        loading,
        connectWallet,
        disconnectWallet,
      }}
    >
      {children}
    </FavorletContext.Provider>
  );
}
