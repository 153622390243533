
import { createContext, useContext } from 'react';

export const MyInfoContext = createContext({
    userInfo: {},
    updateUserInfo: () => { }
});

export function useMyInfo() {
    return useContext(MyInfoContext);
}
