import React, { useEffect } from "react";
import styles from "./index.module.scss";
import cn from "classnames";

import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { GameCard } from "./card";

export const Introduce = () => {
  const expList = [
    {
      src: require("../../../../../assets/images/homepage/juicy_game1.png"),
      title: (
        <>
          Level Up <br className="pc" /> Your Shooter!
        </>
      ),
      text: <>Collect and upgrade powerful shooters with unique skills and personalities! Show off your style with $JELLY Doubler Skin.</>,
    },
    {
      src: require("../../../../../assets/images/homepage/juicy_game2.png"),
      title: (
        <>
          Collect Fruit to <br className="pc" /> Make Juice!
        </>
      ),
      text: <>Take out opponents and gather fruits to power up, heal, and obtain powerful battle items!</>,
    },
    {
      src: require("../../../../../assets/images/homepage/juicy_game3.png"),
      title: <>5v5 Multiplayer!</>,
      text: <>Team up and become the ultimate shooter. The winner takes it all!</>,
    },
    {
      src: require("../../../../../assets/images/homepage/juicy_game4.png"),
      title: (
        <>
          Battle in Multiple <br className="pc" /> Game Modes
        </>
      ),
      text: <>Try various challenging game modes while earning $JELLY! Risk to Earn, Skill to Earn and Collect to Earn are waiting for you.</>,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, []);

  return (
    <section className={styles.section}>
      <div className={cn("container", styles.container)}>
        <div className={styles.title_box}>Shoot, Survive, and Victory!</div>
        <div className={styles.subtitle_box}>Fast-paced 5v5 casual shooter</div>
        <div className={styles.content_box}>
          {expList.map((exp, index) => (
            <GameCard className={styles.card} key={index} data={exp} />
          ))}
        </div>
      </div>
    </section>
  );
};
