import { useState } from "react";
import { MyInfoContext } from "../hooks/useMyInfo";

export const userInitState = {
    address: "",
    name: "",
    email: "",
    bio: "",
    avatar: "",
    background: "",
    customUrl: "",
    twitter: "",
    discord: "",
    joinDate: "",
    grade: 0,
    balance: "",
    admin: false,
}

export const MyInfoProvider = ({ children }) => {

    const [userInfo, setUserInfo] = useState(userInitState);

    const updateUserInfo = (user) => {
        // if (user) {
        //     setUserInfo({
        //         address: user.Address,
        //         name: user.Name,
        //         email: user.Email ? user.Email : "",
        //         bio: user.Bio ? user.Bio : "",
        //         avatar: user.Avatar ? user.Avatar : "",
        //         background: user.Background ? user.Background : "",
        //         customUrl: user.CustomUrl ? user.CustomUrl : "",
        //         twitter: user.Twitter ? user.Twitter : "",
        //         instagram: user.Instagram ? user.Instagram : "",
        //         joinDate: user.JoinTime ? user.JoinTime : "",
        //         admin: user.Admin
        //     })
        // } else {
        //     setUserInfo(userInitState)
        // }
    }

    return (
        <MyInfoContext.Provider value={{ userInfo, updateUserInfo }}>
            {children}
        </MyInfoContext.Provider>
    )
}
