import React, { useEffect } from "react";
import styles from "./index.module.scss";
import cn from "classnames";
import { LinkButton } from "../../../../elements/linkbutton";

import { clearAllBodyScrollLocks } from "body-scroll-lock";

export const Banner = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, []);

  return (
    <section className={styles.section}>
      <div className={styles.background_box}>
        <img className={cn(styles.background_img)} src={require("../../../../../assets/images/homepage/norma_banner_bg.png")} alt="background" />
      </div>
      <div className={cn("container", styles.container)}>
        <div className={styles.logo_box}>
          <img src={require("../../../../../assets//images//homepage//norma_banner_logo.png")} alt="logo" />
        </div>
        <div className={styles.mobile_bg_box}>
          <div className={styles.subtitle_box}>Don’t miss out on the latest event updates, including the World Chef Championship, Chocolate Pop-up Store, Cake Festival, and more!</div>
          <div className={styles.button_box}>
            <LinkButton className={styles.button} link="https://x.com/norma_metaland">
              Go to
              <img src={require("../../../../../assets/images/homepage/x_icon.png")} alt="x" />
            </LinkButton>
          </div>
        </div>
      </div>
    </section>
  );
};
