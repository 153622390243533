import React, { useEffect, useRef } from "react";
import styles from "./index.module.scss";
import cn from "classnames";
import { EcoCard } from "./card";
import { sleep } from "../../../../../utility/helpers";
import { isMobile, isMobileWidth } from "../../../../../utility/helpers";

import { clearAllBodyScrollLocks } from "body-scroll-lock";

export const Ecosystem = () => {
  const sectionRef = useRef();
  const economics = [
    {
      title: (
        <>
          Buy
          <img className={styles.gram_icon} src={require("../../../../../assets/images/homepage/eco_gram_icon.png")} alt="gram" />
          $GRAM
        </>
      ),
      contents: (
        <>
          Purchase $GRAM on available exchanges.
          <br />
          $GRAM is the governance token that gives you access to the entire GRAM economy.
        </>
      ),
      button: "Buy Here",
      disabled: true,
      link: "/",
      reverseColor: true,
      ref: useRef(null),
    },
    {
      title: (
        <>
          Stake
          <img className={styles.gram_icon} src={require("../../../../../assets/images/homepage/eco_gram_icon.png")} alt="gram" />
          $GRAM
        </>
      ),
      contents: "Stake $GRAM to earn rewards and participate in the DAO with $sGRAM.",
      button: "Stake Now",
      link: "/staking",
      reverseColor: false,
      ref: useRef(null),
    },
    {
      title: (
        <>
          Exchange
          <img className={styles.gram_icon} src={require("../../../../../assets/images/homepage/eco_gram_icon.png")} alt="gram" />
          $GRAM
        </>
      ),
      contents: "Exchange $GRAM for utility tokens within each service of the economy.",
      button: "Exchange Now",
      link: "/exchange/candy",
      reverseColor: false,
      ref: useRef(null),
    },
    {
      title: (
        <>
          <img className={styles.gram_icon} src={require("../../../../../assets/images/homepage/eco_gram_icon.png")} alt="gram" />
          $GRAM Ecosystem Community
        </>
      ),
      contents: "Take part in the conversation! Stay in the know & be the first to hear about any new updates",
      social: [
        {
          src: require("../../../../../assets/images/homepage/social_x.png"),
          link: "https://twitter.com/gram_voyage",
        },
        {
          src: require("../../../../../assets/images/homepage/social_d.png"),
          link: "https://discord.gg/gramecosystem",
        },
        {
          src: require("../../../../../assets/images/homepage/social_t.png"),
          link: "https://t.me/gram_voyage",
        },
        {
          src: require("../../../../../assets/images/homepage/social_m.png"),
          link: "https://medium.com/@gram-voyage",
        },
      ],
      reverseColor: false,
      ref: useRef(null),
    },
    {
      title: <>$GRAM Ecosystem Whitepaper</>,
      contents: "Learn more about us",
      button: "Read Now",
      link: "https://grampus-cwc.gitbook.io/gram_voyage/tokenomics/governance-token-usdgram-token",
      reverseColor: true,
      ref: useRef(null),
    },
  ];

  // 현재 스크롤이 sectionRef에 닿으면 imgList의 모든 ref의 opacity를 1로 변경하고 translateY(0)으로 변경합니다.
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            economics.forEach(async (eco, index) => {
              await sleep((index % 3) * 100 + Math.floor(index / 3) * 80 + 300);
              if (eco.ref.current) {
                eco.ref.current.style.transform = "translateX(0)";
              }
            });
          } else {
            economics.forEach(async (eco) => {
              if (eco.ref.current) {
                eco.ref.current.style.transform = "translateX(100vw)";
              }
            });
          }
        });
      },
      {
        threshold: isMobile || isMobileWidth() ? 0.1 : 0.5,
      }
    );

    observer.observe(sectionRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, []);

  return (
    <section className={styles.section} ref={sectionRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.title_box}>$GRAM Ecosystem</div>
        <div className={styles.subtitle_box}>Discover the GRAM Ecosystem, including the community, decentralized assets, and more.</div>
        <div className={styles.cards_box}>
          {economics.map((eco, index) => (
            <EcoCard className={styles.card} key={index} data={eco} />
          ))}
        </div>
      </div>
    </section>
  );
};
