import { useEffect, useState } from "react";

import Web3 from "web3";
import cn from "classnames";

import styles from "./WalletProvider.module.scss";
import { WalletContext } from "../hooks/useWallet";
import Modal from "../../../../components/elements/modal/Modal";
import { ReactComponent as ModalClose } from "../../../../assets/images/homepage/connect_wallet_modal_close.svg";
import { getShortenedAddress, isMobile } from "../../../../utility/helpers";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import { useMetamask } from "../hooks/useMetamask";
import { useKaikas } from "../hooks/useKaikas";
import { useFavorlet } from "../hooks/useFavorlet";
import QRCode from "react-qr-code";

export const WalletProvider = ({ children }) => {
  const { selectedAccount: selectedMetamaskAccount, connectWallet: connectMetamas, disconnectWallet: disconnectMetamas, loading: loadingMetamask } = useMetamask();
  const { selectedAccount: selectedKaikasAccount, connectWallet: connectKaikas, disconnectWallet: disconnectKaikas, loading: loadingKaikas } = useKaikas();
  const { selectedAccount: selectedFavorletAccount, connectWallet: connectFavorlet, disconnectWallet: disconnectFavorlet, loading: loadingFavorlet } = useFavorlet();

  const [selectedAccount, setSelectedAccount] = useState("");
  const [connectedWalletName, setConnectedWalletName] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isConfirmModalShowing, setIsConfirmModalShowing] = useState(false);
  const [isWalletConnectModalShowing, setIsWalletConnectModalShowing] = useState(false);

  const [infoModalContents, setInfoModalContents] = useState({
    title: "",
    description: "",
    error: false,
    qr: "",
    qrCallback: null,
  });

  useEffect(() => {
    if (isWalletConnectModalShowing) {
      disableBodyScroll(document.querySelector("#root"));
    } else {
      clearAllBodyScrollLocks();
    }
  }, [isWalletConnectModalShowing]);

  const showCommingSoonModal = (name) => {
    setInfoModalContents({
      title: "Coming Soon",
      description: name + " is coming soon!",
      error: false,
      qr: "",
      qrCallback: null,
    });
    setIsConfirmModalShowing(true);
  };

  const showErrorModal = (error) => {
    setInfoModalContents({
      title: "Error",
      description: error,
      error: true,
      qr: "",
      qrCallback: null,
    });
    setIsConfirmModalShowing(true);
  };

  const showQrModal = (url, callback) => {
    setInfoModalContents({
      title: "Connect Wallet",
      description: isMobile ? 'If you have connected your wallet in the Favorlet app, please click "OK" to proceed.' : "Please scan the QR code below with your mobile device to connect to your Favorlet Wallet.",
      error: false,
      qr: url,
      qrCallback: callback,
    });
    setIsConfirmModalShowing(true);
  };

  useEffect(() => {
    if (selectedMetamaskAccount) {
      setSelectedAccount(selectedMetamaskAccount);
      setConnectedWalletName("Metamask");
    } else if (selectedKaikasAccount) {
      setSelectedAccount(selectedKaikasAccount);
      setConnectedWalletName("Kaikas");
    } else if (selectedFavorletAccount) {
      setSelectedAccount(selectedFavorletAccount);
      setConnectedWalletName("Favor Wallet");
    } else {
      setSelectedAccount("");
      setConnectedWalletName("");
    }
  }, [selectedMetamaskAccount, selectedKaikasAccount, selectedFavorletAccount]);

  useEffect(() => {
    setLoading(loadingMetamask || loadingKaikas || loadingFavorlet);
  }, [loadingMetamask, loadingKaikas, loadingFavorlet]);

  const supportedWalletList = [
    {
      name: "Metamask",
      icon: require("../../../../assets/images/homepage/Metamask.png"),
      connect: () => connectMetamas(),
      disconnect: () => disconnectMetamas(),
    },
    {
      name: "Kaikas",
      icon: require("../../../../assets/images/homepage/Kaikas.png"),
      connect: () => connectKaikas(showErrorModal),
      disconnect: () => disconnectKaikas(),
    },
    {
      name: "Face Wallet",
      icon: require("../../../../assets/images/homepage/FaceWallet.png"),
      connect: () => showCommingSoonModal("Face Wallet"),
    },
    {
      name: "Favor Wallet",
      icon: require("../../../../assets/images/homepage/FavorWallet.png"),
      connect: () => connectFavorlet(showQrModal, showErrorModal),
      disconnect: () => disconnectFavorlet(),
    },
    {
      name: "Havah Wallet",
      icon: require("../../../../assets/images/homepage/HavahWallet.png"),
      connect: () => showCommingSoonModal("Havah Wallet"),
    },
  ];

  useEffect(() => {
    if (isWalletConnectModalShowing) {
      disableBodyScroll(document.querySelector("#modal"));
    } else {
      clearAllBodyScrollLocks();
    }
  }, [isWalletConnectModalShowing]);

  const closeConfirmModal = () => {
    if (infoModalContents.qrCallback) {
      infoModalContents.qrCallback();
    }
    setIsConfirmModalShowing(false);
  };

  const showWalletConnectModal = () => {
    setIsWalletConnectModalShowing(true);
  };

  return (
    <WalletContext.Provider
      value={{
        showWalletConnectModal,
        selectedAccount,
        connectedWalletName,
        error,
        loading,
      }}
    >
      {children}
      <Modal outerClassName={styles.outer_modal} visible={isWalletConnectModalShowing} onClose={() => setIsWalletConnectModalShowing(false)} unquenchable={loading || isConfirmModalShowing} modalCloseIcon={<ModalClose />}>
        <div className={styles.modal_container}>
          <div className={styles.modal_header}>Connect Wallet</div>

          <div className={styles.modal_body}>
            {supportedWalletList.map((wallet, index) => {
              return (
                <div className={cn(styles.wallet_item, { [styles.disabled]: connectedWalletName && connectedWalletName !== wallet.name }, { [styles.connected]: connectedWalletName && connectedWalletName === wallet.name })} key={index}>
                  <div className={styles.wallet_row}>
                    <div className={styles.wallet_icon}>
                      <img src={wallet.icon} alt={wallet.name} />
                    </div>
                    <div className={styles.wallet_inner_row}>
                      <div className={styles.wallet_name}>{wallet.name}</div>
                      <div className={styles.wallet_address}>{connectedWalletName && connectedWalletName === wallet.name ? getShortenedAddress(selectedAccount) : ""}</div>
                    </div>
                  </div>
                  <div className={styles.wallet_row}>
                    <div className={cn(styles.wallet_connect, { [styles.loading]: loading })}>
                      <button onClick={connectedWalletName && connectedWalletName === wallet.name ? wallet.disconnect : wallet.connect}>{connectedWalletName && connectedWalletName === wallet.name ? "Disconnect" : "Connect"}</button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className={styles.modal_footer}>
            <div className={styles.modal_footer_text}>Only Klaytn chain wallets are supported. If it's not Klaytn NFT, please bridge it using HAVAH MITTER!</div>
            <div className={styles.modal_footer_button}>
              <a href="https://mitter.havah.io/bridge/nft" target="_blank" rel="noreferrer">
                Go to HAVAH MITTER
              </a>
            </div>
          </div>
        </div>
      </Modal>
      <Modal outerClassName={styles.outer_confirm_modal} visible={isConfirmModalShowing} onClose={() => closeConfirmModal()} unquenchable={false}>
        <div className={styles.confirm_modal_container}>
          <div className={styles.confirm_modal_header}>{infoModalContents.title}</div>
          <div className={styles.confirm_modal_body}>{infoModalContents.description}</div>
          {infoModalContents.qr && (
            <div className={styles.confirm_modal_qr}>
              {infoModalContents.qr && <QRCode value={infoModalContents.qr} />}
              <div className={styles.confirm_modal_qr_body}>If you have connected your wallet in the Favorlet app, please click "OK" to proceed.</div>
            </div>
          )}
          <div className={styles.confirm_modal_footer}>
            <button className={cn(styles.confirm_modal_btn, { [styles.error]: infoModalContents.error })} onClick={() => closeConfirmModal()}>
              OK
            </button>
          </div>
        </div>
      </Modal>
    </WalletContext.Provider>
  );
};
