import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./index.module.scss";
import YouTube from "@u-wave/react-youtube";

import { ReactComponent as VolumnOn } from "../../../../../assets/images/homepage/volume_on.svg";
import { ReactComponent as VolumnOff } from "../../../../../assets/images/homepage/volume_off.svg";

export const Movie = () => {
  const [muted, setMuted] = useState(true);

  const toggleMute = () => {
    setMuted(!muted);
  };

  const onEnd = (event) => {
    event.target.playVideo();
  };

  return (
    <section className={styles.section}>
      <div className={cn("container", styles.container)}>
        <YouTube className={styles.video} autoplay={true} muted={muted} loop={true} controls={false} showCaptions={false} disableKeyboard={true} showInfo={false} onEnd={onEnd} playsInline={true} video="I483_pq2ps8" />

        <div className={styles.overlay}>
          <div className={styles.sub_title}>
            NORMA IN
            <br />
            METALAND
          </div>
          <div className={styles.contents}>
            You can cook all the tasty dishes <br className="pc" /> and fantastic desserts of the world! <br className="pc" /> Let's go off on a cooking journey with Norma!
          </div>
          <div className={styles.buttons}>
            <a href="https://apps.apple.com/app/id6474652575" className={styles.button} target="_blank">
              <img src={require("../../../../../assets/images/homepage/apple_button.png")} />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.grampus.cookingnormametaland" className={styles.button} target="_blank">
              <img src={require("../../../../../assets/images/homepage/google_button.png")} />
            </a>
            <a href="https://web3.norma.land" className={styles.button} target="_blank">
              <img src={require("../../../../../assets/images/homepage/pc_button.png")} />
            </a>
          </div>
        </div>
        <div className={styles.mute_container}>
          <button className={styles.mute_button} onClick={() => toggleMute()}>
            {muted ? (
              <>
                <VolumnOff name="volume" size="24" /> <span>BGM OFF</span>
              </>
            ) : (
              <>
                <VolumnOn name="volume" size="24" />
                <span>BGM ON</span>
              </>
            )}
          </button>
        </div>
      </div>
    </section>
  );
};
