import axios from "axios";
import { getLocalStorage } from "../storage";

class BaseAPIClient {
  constructor(baseUrl, header) {
    this.baseUrl = baseUrl;
    this.header = header;
  }

  logging(msg) {
    if (process.env.REACT_APP_ENV != "production") {
      console.log(msg);
    }
  }

  post = async (endpoint, body) => {
    const res = await axios.post(this.baseUrl + endpoint, body, {
      headers: { ...this.header, Authorization: `Bearer ${getLocalStorage("token")}` },
    });

    this.logging({
      endpoint: this.baseUrl + endpoint,
      body: body,
      response: res?.data,
    });

    return res?.data;
  };

  get = async (endpoint) => {
    const res = await axios.get(this.baseUrl + endpoint, {
      headers: { ...this.header, Authorization: `Bearer ${getLocalStorage("token")}` },
    });

    this.logging({
      endpoint: this.baseUrl + endpoint,
      response: res?.data,
    });

    return res?.data;
  };
}

export default BaseAPIClient;
