import React, { useCallback, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import OutsideClickHandler from "react-outside-click-handler";
import cn from "classnames";
import styles from "./Modal.module.scss";
import { ReactComponent as ModalClose } from "../../../assets/images/homepage/modal_close.svg";

const Modal = ({ outerClassName, containerClassName, visible, onClose, children, unquenchable, modalCloseIcon }) => {
  const escFunction = useCallback(
    (e) => {
      if (e.keyCode === 27 && !unquenchable) {
        closeModal();
      }
    },
    [closeModal]
  );

  function closeModal() {
    onClose();
  }

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    if (visible) {
      const target = document.querySelector("#modal");
      disableBodyScroll(target);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [visible]);

  return createPortal(
    visible && (
      <div className={styles.modal} id="modal">
        <div className={cn(styles.outer, outerClassName)}>
          <OutsideClickHandler
            onOutsideClick={() => {
              if (!unquenchable) closeModal();
            }}
          >
            <div className={cn(styles.container, containerClassName)}>
              {children}
              {unquenchable == false && (
                <button className={styles.close} onClick={closeModal}>
                  {modalCloseIcon ? modalCloseIcon : <ModalClose />}
                </button>
              )}
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    ),
    document.body
  );
};

export default Modal;
