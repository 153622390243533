import React, { useEffect, useState, useRef } from "react";
import styles from "./index.module.scss";
import cn from "classnames";

import { clearAllBodyScrollLocks } from "body-scroll-lock";

export const GameCard = (props) => {
  const cardRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, []);

  return (
    <div className={cn(styles.card, props.className)} ref={cardRef}>
      <div className={styles.img_box}>
        <img className={styles.img} src={props.data.img} alt="game" />
      </div>
      <div className={styles.title_box}>{props.data.title}</div>
      <div className={styles.name_box}>{props.data.name}</div>
    </div>
  );
};
