import Web3 from "web3";

import { useEffect, useState } from "react";
import { IskraContext } from "../hooks/useIskra";
import { IskraSDK, LoginOptionBuilder } from "@iskraworld/iskra-sdk";
import { convertFromWei } from "../../../../utility/helpers";
import ERC721Abi from "../../../../assets/contract/ERC721.abi";
import ERC20Abi from "../../../../assets/contract/ERC20.abi";
import StakingAbi from "../../../../assets/contract/Staking.abi";
import ExchangeAbi from "../../../../assets/contract/Exchange.abi";
import ExchangePoolAbi from "../../../../assets/contract/ExchangePool.abi";
import normaServerClient from "../../../../module/api/grampus";
import { getShortenedAddress, sleep } from "../../../../utility/helpers";

const klaytnChainData = {
  chainId: 0x2019,
  name: "Klaytn",
  rpcUrl: "https://public-en-cypress.klaytn.net",
  scopeUrl: "https://scope.klaytn.com/",
  symbol: "KLAY",
  decimals: 18,
};
const klaytnBaobabChainData = {
  chainId: 0x3e9,
  name: "Klaytn Baobab",
  rpcUrl: "https://api.baobab.klaytn.net:8651",
  scopeUrl: "https://baobab.scope.klaytn.com/",
  symbol: "KLAY",
  decimals: 18,
};

export const IskraProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState();

  const [candyContractAddress, setCandyContractAddress] = useState("");
  const [gramContractAddress, setGramContractAddress] = useState("");
  const [sgramContractAddress, setSgramContractAddress] = useState("");
  const [expgramContractAddress, setExpgramContractAddress] = useState("");
  const [iskContractAddress, setIskContractAddress] = useState("");
  const [candyBalance, setCandyBalance] = useState(0);
  const [gramBalance, setGramBalance] = useState(0);
  const [sgramBalance, setSgramBalance] = useState(0);
  const [expgramBalance, setExpgramBalance] = useState(0);
  const [iskBalance, setIskBalance] = useState(0);

  const [iskraProvider, setIskraProvider] = useState(null);
  const [walletType, setWalletType] = useState(null);
  const [provider, setProvider] = useState(null);

  const [erc721Contract, setErc721Contract] = useState(null);
  const [erc20Contract, setErc20Contract] = useState(null);
  const [stakingContract, setStakingContract] = useState(null);
  const [exchangeContract, setExchangeContract] = useState(null);
  const [exchangePoolContract, setExchangePoolContract] = useState(null);

  useEffect(() => {
    const appId = process.env.REACT_APP_ISKRA_APP_ID;
    const phase = process.env.REACT_APP_ISKRA_PHASE;

    IskraSDK.instance()
      .initializeAsync(phase, appId)
      .then(async () => {
        if (!isLoginExpired()) {
          login(true).catch(console.log);
        }
      })
      .catch((error) => {
        console.error("IskraSDK.initializeAsync() failed, error = " + error.message);
      });

    normaServerClient
      .getContract("candy")
      .then((response) => {
        setCandyContractAddress(response.data.Address);
      })
      .catch((error) => {
        console.log(error);
      });

    normaServerClient
      .getContract("gram")
      .then((response) => {
        setGramContractAddress(response.data.Address);
      })
      .catch((error) => {
        console.log(error);
      });

    normaServerClient
      .getContract("isk")
      .then((response) => {
        setIskContractAddress(response.data.Address);
      })
      .catch((error) => {
        console.log(error);
      });

    normaServerClient
      .getContract("staking")
      .then((response) => {
        setSgramContractAddress(response.data.Address);
      })
      .catch((error) => {
        console.log(error);
      });

    normaServerClient
      .getContract("expgram")
      .then((response) => {
        setExpgramContractAddress(response.data.Address);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (!iskraProvider) {
      return;
    }

    const web3 = new Web3();

    fetch(ERC721Abi)
      .then((res) => res.text())
      .then((data) => setErc721Contract(new web3.eth.Contract(JSON.parse(data))))
      .catch((err) => console.error(err));
    fetch(ERC20Abi)
      .then((res) => res.text())
      .then((data) => setErc20Contract(new web3.eth.Contract(JSON.parse(data))))
      .catch((err) => console.error(err));
    fetch(StakingAbi)
      .then((res) => res.text())
      .then((data) => setStakingContract(new web3.eth.Contract(JSON.parse(data))))
      .catch((err) => console.error(err));
    fetch(ExchangeAbi)
      .then((res) => res.text())
      .then((data) => setExchangeContract(new web3.eth.Contract(JSON.parse(data))))
      .catch((err) => console.error(err));
    fetch(ExchangePoolAbi)
      .then((res) => res.text())
      .then((data) => setExchangePoolContract(new web3.eth.Contract(JSON.parse(data))))
      .catch((err) => console.error(err));
  }, [iskraProvider]);

  useEffect(() => {
    if (erc20Contract && erc721Contract && stakingContract && exchangeContract && exchangePoolContract && iskContractAddress) {
      console.log("loading complete");
      setLoading(false);
    }
  }, [erc20Contract, erc721Contract, stakingContract, exchangeContract, exchangePoolContract, iskContractAddress]);

  useEffect(() => {
    if (selectedAccount && candyContractAddress && erc20Contract) {
      updateCandyBalance(selectedAccount);
    } else {
      setCandyBalance(0);
    }
  }, [selectedAccount, candyContractAddress, erc20Contract]);

  useEffect(() => {
    if (selectedAccount && gramContractAddress && erc20Contract) {
      updateGramBalance(selectedAccount);
    } else {
      setGramBalance(0);
    }
  }, [selectedAccount, gramContractAddress, erc20Contract]);

  useEffect(() => {
    if (selectedAccount && sgramContractAddress && erc20Contract) {
      updateSGramBalance(selectedAccount);
    } else {
      setSgramBalance(0);
    }
  }, [selectedAccount, sgramContractAddress, erc20Contract]);

  useEffect(() => {
    if (selectedAccount && expgramContractAddress && erc20Contract) {
      updateExpGramBalance(selectedAccount);
    } else {
      setExpgramBalance(0);
    }
  }, [selectedAccount, expgramContractAddress, erc20Contract]);

  useEffect(() => {
    if (selectedAccount && iskContractAddress && erc20Contract) {
      updateIskBalance(selectedAccount);
    } else {
      setIskBalance(0);
    }
  }, [selectedAccount, iskContractAddress, erc20Contract]);

  const isLoginExpired = () => {
    const loginExpiredTime = localStorage.getItem("loginExpiredTime");
    if (loginExpiredTime && loginExpiredTime > Date.now()) {
      return false;
    }
    return true;
  };

  const setExpiredTime = () => {
    const expiredTime = Date.now() + 1000 * 60 * 60 * 24; // 1 day
    localStorage.setItem("loginExpiredTime", expiredTime);
  };

  const resetExpiredTime = () => {
    localStorage.removeItem("loginExpiredTime");
  };

  const updateCandyBalance = (walletAddress) => {
    if (!walletAddress) {
      return;
    }

    const params = [
      {
        to: candyContractAddress,
        from: selectedAccount,
        data: erc20Contract.methods.balanceOf(walletAddress).encodeABI(),
      },
    ];

    iskraProvider
      .request({
        method: "eth_call",
        params,
      })
      .then((result) => {
        setCandyBalance(convertFromWei(result));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateGramBalance = (walletAddress) => {
    if (!walletAddress) {
      return;
    }

    const params = [
      {
        to: gramContractAddress,
        from: selectedAccount,
        data: erc20Contract.methods.balanceOf(walletAddress).encodeABI(),
      },
    ];

    iskraProvider
      .request({
        method: "eth_call",
        params,
      })
      .then((result) => {
        setGramBalance(convertFromWei(result));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateSGramBalance = (walletAddress) => {
    if (!walletAddress) {
      return;
    }

    const params = [
      {
        to: sgramContractAddress,
        from: selectedAccount,
        data: erc20Contract.methods.balanceOf(walletAddress).encodeABI(),
      },
    ];

    iskraProvider
      .request({
        method: "eth_call",
        params,
      })
      .then((result) => {
        setSgramBalance(convertFromWei(result));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateExpGramBalance = (walletAddress) => {
    if (!walletAddress) {
      return;
    }

    const params = [
      {
        to: expgramContractAddress,
        from: selectedAccount,
        data: erc20Contract.methods.balanceOf(walletAddress).encodeABI(),
      },
    ];

    iskraProvider
      .request({
        method: "eth_call",
        params,
      })
      .then((result) => {
        setExpgramBalance(convertFromWei(result));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateIskBalance = (walletAddress) => {
    if (!walletAddress) {
      return;
    }

    const params = [
      {
        to: iskContractAddress,
        from: selectedAccount,
        data: erc20Contract.methods.balanceOf(walletAddress).encodeABI(),
      },
    ];

    iskraProvider
      .request({
        method: "eth_call",
        params,
      })
      .then((result) => {
        setIskBalance(convertFromWei(result));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  function handleAccountsChanged(accounts) {
    setSelectedAccount(accounts[0]);
    // console.log("handleAccountsChanged:" + accounts[0]);
  }

  const changeToVaildAddressAndNetwork = async () => {
    if (walletType === "iskra") {
      return true;
    }

    const chainId = await provider.request({ method: "eth_chainId" });
    const targetChainData = process.env.REACT_APP_ENV !== "production" && process.env.REACT_APP_ENV !== "staging" ? klaytnBaobabChainData : klaytnChainData;
    if (process.env.REACT_APP_ENV !== "production") {
      console.log("chainId", chainId);
      console.log("targetChainData", targetChainData.chainId);
    }

    if (Number(chainId) !== Number(targetChainData.chainId)) {
      // add network
      try {
        await provider.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: `0x${targetChainData.chainId.toString(16)}`,
              chainName: targetChainData.name,
              nativeCurrency: {
                name: targetChainData.symbol,
                symbol: targetChainData.symbol,
                decimals: targetChainData.decimals,
              },
              rpcUrls: [targetChainData.rpcUrl],
              blockExplorerUrls: [targetChainData.scopeUrl],
            },
          ],
        });
      } catch (error) {
        console.error(error);
        return false;
      }

      // change network
      try {
        await provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${targetChainData.chainId.toString(16)}` }],
        });
      } catch (error) {
        console.error(error);
        return false;
      }
    }

    let selectedAccounts = await provider.request({ method: "eth_accounts" });

    if (!selectedAccount) {
      await requestAccountsFromIskra();
      return false;
    }

    if (!selectedAccounts || selectedAccounts.length <= 0 || selectedAccount.toLowerCase() !== selectedAccounts[0].toLowerCase()) {
      try {
        if (walletType === "metamask" && selectedAccounts && selectedAccounts.length > 0) {
          await provider.request({
            method: "wallet_requestPermissions",
            params: [
              {
                eth_accounts: {},
              },
            ],
          });
        }

        selectedAccounts = await provider.request({
          method: "eth_requestAccounts",
          params: [
            {
              eth_accounts: {},
            },
          ],
        });
        console.log("selectedAccounts", selectedAccounts);
      } catch (error) {
        console.error(error);
        return false;
      }
    }

    if (!selectedAccounts || selectedAccounts.length <= 0 || selectedAccount.toLowerCase() !== selectedAccounts[0].toLowerCase()) {
      throw new Error("The wallet address linked to the Iskra account is different from the currently linked wallet address. Please connect to the wallet below and try again.\n Address : " + getShortenedAddress(selectedAccount));
    }

    return true;
  };

  const requestAccountsFromIskra = async () => {
    let p = iskraProvider;
    if (!p) {
      const newIskraProvider = await IskraSDK.instance().getIskraWalletProvider();

      setIskraProvider(newIskraProvider);

      p = newIskraProvider;
    }

    const walletAddress = await p.request({
      method: "eth_requestAccounts",
      params: [
        {
          eth_accounts: {},
        },
      ],
    });

    handleAccountsChanged(walletAddress);
  };

  const setLoginData = async (data, resolve, reject) => {
    const { provider, error, type } = await IskraSDK.instance().getWalletProvider();
    const iskraProvider = await IskraSDK.instance().getIskraWalletProvider();

    if (error) {
      resetExpiredTime();
      reject(error.message);
      return;
    }

    setIskraProvider(iskraProvider);
    setProvider(provider);
    setWalletType(type);
    setUserData(data);

    if (process.env.REACT_APP_ENV !== "production") {
      console.log("iskra provider", iskraProvider);
      console.log("provider", provider);
      console.log("wallet type", type);
      console.log(data);
    }

    requestAccountsFromIskra();
    resolve();
  };

  const login = (sticky = false) =>
    new Promise((resolve, reject) => {
      if (isLoginExpired()) {
        // When login is expired, login again
        const loginOption = new LoginOptionBuilder()
          .enableIskraWallet() // If a wallet creation is needed from the beginning
          .build();
        IskraSDK.instance().login(loginOption, async (data, error) => {
          if (error != null) {
            reject(error.message);
          } else {
            setLoginData(data, resolve, reject);
            setExpiredTime();
          }
        });
      } else {
        IskraSDK.instance().loadLastLogin(async (data, error) => {
          console.log("data", data);
          console.log("error", error);
          if (error != null) {
            // has no last login data
            if (sticky) {
              resetExpiredTime();
              return;
            }

            const loginOption = new LoginOptionBuilder()
              .enableIskraWallet() // If a wallet creation is needed from the beginning
              .build();
            IskraSDK.instance().login(loginOption, async (data, error) => {
              console.log("data", data);
              console.log("error", error);
              if (error != null) {
                reject(error.message);
              } else {
                setLoginData(data, resolve, reject);
                setExpiredTime();
              }
            });
          } else {
            // has last login data
            setLoginData(data, resolve, reject);
          }
        });
      }
    });

  const logout = () => {
    setUserData(null);
    setSelectedAccount("");
    resetExpiredTime();
  };

  const balanceOf = async (walletAddress, contractAddress) => {
    if (!erc20Contract) {
      return 0;
    }

    const params = [
      {
        to: contractAddress,
        from: selectedAccount,
        data: erc20Contract.methods.balanceOf(walletAddress).encodeABI(),
      },
    ];

    return new Promise((resolve, reject) => {
      iskraProvider
        .request({
          method: "eth_call",
          params,
        })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const tokenOfOwnerByIndex = async (walletAddress, contractAddress, index) => {
    if (!erc721Contract) {
      return -1;
    }

    const params = [
      {
        to: contractAddress,
        from: selectedAccount,
        data: erc721Contract.methods.tokenOfOwnerByIndex(walletAddress, index).encodeABI(),
      },
    ];

    return new Promise((resolve, reject) => {
      iskraProvider
        .request({
          method: "eth_call",
          params,
        })
        .then((result) => {
          resolve(Web3.utils.toNumber(result));
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const nftIdsList = async (walletAddress, contractAddress) =>
    new Promise(async (resolve, reject) => {
      balanceOf(walletAddress, contractAddress)
        .then((res) => {
          const balance = Web3.utils.toNumber(res);
          if (balance === 0) resolve([]);

          const nftIds = [];
          for (let i = 0; i < balance; i++) {
            tokenOfOwnerByIndex(walletAddress, contractAddress, i)
              .then((res) => {
                if (res === -1) {
                  resolve([]);
                }

                nftIds.push(res);
                if (nftIds.length === balance) {
                  resolve(nftIds.sort((a, b) => a - b));
                }
              })
              .catch((err) => {
                reject(err);
              });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

  const stake = async (walletAddress, contractAddress, amount) => {
    if (!stakingContract) {
      console.error("stakingContract is null in stake()");
      console.trace();
      throw new Error("Contract initialization failed.\nPlease refresh the page and try again.\ncode : 8003");
    }

    const status = await changeToVaildAddressAndNetwork();
    if (!status) {
      console.error("changeToVaildAddressAndNetwork failed in stake()");
      throw new Error("The wallet address or network is not selected correctly..\ncode : 9003");
    }

    const params = [
      {
        from: walletAddress,
        to: contractAddress,
        gas: "0x55730", // 350000
        gasPrice: "0xCA18D4E80", // 0.00000005425KLAY(54.25ston)
        data: stakingContract.methods.stake(amount).encodeABI(),
      },
    ];

    return new Promise(async (resolve, reject) => {
      provider
        .request({
          method: "eth_sendTransaction",
          params,
        })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getEstimatedStakingGasFee = async (walletAddress, contractAddress, tokenAddress, amount) => {
    if (!walletAddress || !contractAddress || !tokenAddress || !amount || !stakingContract || !erc20Contract) {
      return 0;
    }

    let params = [
      {
        from: walletAddress,
        to: contractAddress,
        data: stakingContract.methods.stake(amount).encodeABI(),
      },
    ];

    return new Promise(async (resolve, reject) => {
      const web3 = new Web3(iskraProvider);
      const gasPrice = await web3.eth.getGasPrice();

      iskraProvider
        .request({
          method: "eth_estimateGas",
          params,
        })
        .then((result) => {
          resolve(Web3.utils.toNumber(result) * gasPrice);
        })
        .catch((error) => {
          params = [
            {
              from: walletAddress,
              to: tokenAddress,
              data: erc20Contract.methods.increaseAllowance(contractAddress, amount).encodeABI(),
            },
          ];

          iskraProvider
            .request({
              method: "eth_estimateGas",
              params,
            })
            .then((result) => {
              resolve(Web3.utils.toNumber(result) * gasPrice);
            })
            .catch((error) => {
              reject(error);
            });
        });
    });
  };

  const getEstimatedUnstakingGasFee = async (walletAddress, contractAddress, tokenAddress, amount) => {
    if (!walletAddress || !contractAddress || !tokenAddress || !amount || !stakingContract || !stakingContract) {
      return 0;
    }

    let params = [
      {
        from: walletAddress,
        to: contractAddress,
        data: stakingContract.methods.withdraw(amount).encodeABI(),
      },
    ];

    return new Promise(async (resolve, reject) => {
      const web3 = new Web3(iskraProvider);
      const gasPrice = await web3.eth.getGasPrice();

      iskraProvider
        .request({
          method: "eth_estimateGas",
          params,
        })
        .then((result) => {
          resolve(Web3.utils.toNumber(result) * gasPrice);
        })
        .catch((error) => {
          params = [
            {
              from: walletAddress,
              to: tokenAddress,
              data: stakingContract.methods.increaseAllowance(contractAddress, amount).encodeABI(),
            },
          ];

          iskraProvider
            .request({
              method: "eth_estimateGas",
              params,
            })
            .then((result) => {
              resolve(Web3.utils.toNumber(result) * gasPrice);
            })
            .catch((error) => {
              reject(error);
            });
        });
    });
  };

  const withdraw = async (walletAddress, contractAddress, amount) => {
    if (!stakingContract) {
      console.error("stakingContract is null in withdraw()");
      console.trace();
      throw new Error("Contract initialization failed.\nPlease refresh the page and try again.\ncode : 8002");
    }

    const status = await changeToVaildAddressAndNetwork();
    if (!status) {
      console.error("changeToVaildAddressAndNetwork failed in withdraw()");
      throw new Error("The wallet address or network is not selected correctly..\ncode : 9002");
    }

    const params = [
      {
        from: walletAddress,
        to: contractAddress,
        gas: "0x55730", // 350000
        gasPrice: "0xCA18D4E80", // 0.00000005425KLAY(54.25ston)
        data: stakingContract.methods.withdraw(amount).encodeABI(),
      },
    ];

    return new Promise(async (resolve, reject) => {
      provider
        .request({
          method: "eth_sendTransaction",
          params,
        })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const increaseAllowance = async (walletAddress, spenderAddress, tokenAddress, amount) => {
    if (!erc20Contract) {
      console.error("erc20Contract is null in increaseAllowance()");
      console.trace();
      throw new Error("Contract initialization failed.\nPlease refresh the page and try again.\ncode : 8001");
    }

    const status = await changeToVaildAddressAndNetwork();
    if (!status) {
      console.error("changeToVaildAddressAndNetwork failed in increaseAllowance()");
      throw new Error("The wallet address or network is not selected correctly.\ncode : 9001");
    }

    const params = [
      {
        from: walletAddress,
        to: tokenAddress,
        gas: "0x16440", // 91200
        gasPrice: "0xCA18D4E80", // 0.00000005425KLAY(54.25ston)
        data: erc20Contract.methods.increaseAllowance(spenderAddress, amount).encodeABI(),
      },
    ];

    return new Promise(async (resolve, reject) => {
      provider
        .request({
          method: "eth_sendTransaction",
          params,
        })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const approve = async (walletAddress, spenderAddress, tokenAddress, amount) => {
    if (!erc20Contract) {
      console.error("erc20Contract is null in approve()");
      console.trace();
      throw new Error("Contract initialization failed.\nPlease refresh the page and try again.\ncode : 8004");
    }

    const status = await changeToVaildAddressAndNetwork();
    if (!status) {
      console.error("changeToVaildAddressAndNetwork failed in increaseAllowance()");
      throw new Error("The wallet address or network is not selected correctly.\ncode : 9004");
    }

    const params = [
      {
        from: walletAddress,
        to: tokenAddress,
        gas: "0x16440", // 91200
        gasPrice: "0xCA18D4E80", // 0.00000005425KLAY(54.25ston)
        data: erc20Contract.methods.approve(spenderAddress, amount).encodeABI(),
      },
    ];

    return new Promise(async (resolve, reject) => {
      provider
        .request({
          method: "eth_sendTransaction",
          params,
        })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const allowance = async (walletAddress, spenderAddress, tokenAddress) => {
    if (!erc20Contract) {
      console.error("erc20Contract is null in allowance()");
      console.trace();
      throw new Error("Contract initialization failed.\nPlease refresh the page and try again.\ncode : 8005");
    }

    const params = [
      {
        to: tokenAddress,
        from: walletAddress,
        data: erc20Contract.methods.allowance(walletAddress, spenderAddress).encodeABI(),
      },
    ];

    return new Promise(async (resolve, reject) => {
      iskraProvider
        .request({
          method: "eth_call",
          params,
        })
        .then((result) => {
          resolve(convertFromWei(result));
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getTotalSupply = async (contractAddress) => {
    if (!erc20Contract) {
      return 0;
    }

    const params = [
      {
        to: contractAddress,
        from: selectedAccount,
        data: erc20Contract.methods.totalSupply().encodeABI(),
      },
    ];

    return new Promise(async (resolve, reject) => {
      iskraProvider
        .request({
          method: "eth_call",
          params,
        })
        .then((result) => {
          resolve(convertFromWei(result));
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const convertOriginTokenToStaking = async (contractAddress, amount) => {
    if (!stakingContract) {
      return 0;
    }

    const params = [
      {
        to: contractAddress,
        from: selectedAccount,
        data: stakingContract.methods.convertOriginTokenToStaking(amount).encodeABI(),
      },
    ];

    return new Promise(async (resolve, reject) => {
      iskraProvider
        .request({
          method: "eth_call",
          params,
        })
        .then((result) => {
          resolve(convertFromWei(result));
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const convertStakingTokenToOrigin = async (contractAddress, amount) => {
    if (!stakingContract) {
      return 0;
    }

    const params = [
      {
        to: contractAddress,
        from: selectedAccount,
        data: stakingContract.methods.convertStakingTokenToOrigin(amount).encodeABI(),
      },
    ];

    return new Promise(async (resolve, reject) => {
      iskraProvider
        .request({
          method: "eth_call",
          params,
        })
        .then((result) => {
          resolve(convertFromWei(result));
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getStartInflationFromTimestamp = async (contractAddress) => {
    if (!stakingContract) {
      return 0;
    }

    const params = [
      {
        to: contractAddress,
        from: selectedAccount,
        data: stakingContract.methods.startInflationFromTimestamp().encodeABI(),
      },
    ];

    return new Promise(async (resolve, reject) => {
      iskraProvider
        .request({
          method: "eth_call",
          params,
        })
        .then((result) => {
          resolve(Web3.utils.toNumber(result));
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getInflationPerSec = async (contractAddress) => {
    if (!stakingContract) {
      return 0;
    }

    const params = [
      {
        to: contractAddress,
        from: selectedAccount,
        data: stakingContract.methods.inflationPerSec().encodeABI(),
      },
    ];

    return new Promise(async (resolve, reject) => {
      iskraProvider
        .request({
          method: "eth_call",
          params,
        })
        .then((result) => {
          resolve(convertFromWei(result));
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getTotalPooledOrigin = async (contractAddress) => {
    if (!stakingContract) {
      return 0;
    }

    const params = [
      {
        to: contractAddress,
        from: selectedAccount,
        data: stakingContract.methods.getTotalPooledOrigin().encodeABI(),
      },
    ];

    return new Promise(async (resolve, reject) => {
      iskraProvider
        .request({
          method: "eth_call",
          params,
        })
        .then((result) => {
          resolve(convertFromWei(result));
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const expectedEarning = async (contractAddress, amount) => {
    if (!stakingContract) {
      return 0;
    }

    const params = [
      {
        to: contractAddress,
        from: selectedAccount,
        data: stakingContract.methods.expectedEarning(amount).encodeABI(),
      },
    ];

    return new Promise(async (resolve, reject) => {
      iskraProvider
        .request({
          method: "eth_call",
          params,
        })
        .then((result) => {
          resolve(convertFromWei(result));
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getEstimatedChangePos = async (walletAddress, contractAddress, inputTokenAddress, amount) => {
    if (!walletAddress || !contractAddress || !inputTokenAddress || !amount || !exchangePoolContract) {
      return 0;
    }

    const params = [
      {
        from: walletAddress,
        to: contractAddress,
        data: exchangePoolContract.methods.estimatePos(inputTokenAddress, amount).encodeABI(),
      },
    ];

    return new Promise(async (resolve, reject) => {
      iskraProvider
        .request({
          method: "eth_call",
          params,
        })
        .then((result) => {
          resolve(convertFromWei(result));
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const exchangePos = async (walletAddress, contractAddress, inputTokenAddress, inputAmount, outputTokenAddress, minOutputAmount) => {
    if (!exchangeContract) {
      console.error("exchangeContract is null in exchangePos()");
      console.trace();
      throw new Error("Contract initialization failed.\nPlease refresh the page and try again.\ncode : 8006");
    }

    const status = await changeToVaildAddressAndNetwork();
    if (!status) {
      console.error("changeToVaildAddressAndNetwork failed in exchangePos()");
      throw new Error("The wallet address or network is not selected correctly.\ncode : 9006");
    }

    const params = [
      {
        from: walletAddress,
        to: contractAddress,
        gas: "0x55730", // 350000
        gasPrice: "0xCA18D4E80", // 0.00000005425KLAY(54.25ston)
        data: exchangeContract.methods.exchangeKctPos(inputTokenAddress, inputAmount, outputTokenAddress, minOutputAmount, []).encodeABI(),
      },
    ];

    return new Promise(async (resolve, reject) => {
      provider
        .request({
          method: "eth_sendTransaction",
          params,
        })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getEstimatedExchangeGasFee = async (walletAddress, contractAddress, inputTokenAddress, inputAmount, outputTokenAddress, minOutputAmount) => {
    if (!walletAddress || !contractAddress || !inputTokenAddress || !inputAmount || !outputTokenAddress || !minOutputAmount || !exchangeContract || !erc20Contract) {
      return 0;
    }

    let params = [
      {
        from: walletAddress,
        to: contractAddress,
        data: exchangeContract.methods.exchangeKctPos(inputTokenAddress, inputAmount, outputTokenAddress, minOutputAmount, []).encodeABI(),
      },
    ];

    return new Promise(async (resolve, reject) => {
      const web3 = new Web3(iskraProvider);
      const gasPrice = await web3.eth.getGasPrice();

      iskraProvider
        .request({
          method: "eth_estimateGas",
          params,
        })
        .then((result) => {
          resolve(Web3.utils.toNumber(result) * gasPrice);
        })
        .catch((error) => {
          params = [
            {
              from: walletAddress,
              to: inputTokenAddress,
              data: erc20Contract.methods.approve(contractAddress, inputAmount).encodeABI(),
            },
          ];

          iskraProvider
            .request({
              method: "eth_estimateGas",
              params,
            })
            .then((result) => {
              resolve(Web3.utils.toNumber(result) * gasPrice);
            })
            .catch((error) => {
              reject(error);
            });
        });
    });
  };

  return (
    <IskraContext.Provider
      value={{
        login,
        logout,
        requestAccountsFromIskra,
        updateCandyBalance,
        updateGramBalance,
        updateSGramBalance,
        updateIskBalance,
        updateExpGramBalance,
        nftIdsList,
        balanceOf,
        getTotalSupply,
        stake,
        withdraw,
        increaseAllowance,
        approve,
        allowance,
        convertOriginTokenToStaking,
        convertStakingTokenToOrigin,
        getStartInflationFromTimestamp,
        getInflationPerSec,
        getTotalPooledOrigin,
        getEstimatedStakingGasFee,
        getEstimatedUnstakingGasFee,
        expectedEarning,
        getEstimatedChangePos,
        exchangePos,
        getEstimatedExchangeGasFee,
        loading,
        userData,
        selectedAccount: selectedAccount,
        candyBalance,
        gramBalance,
        sgramBalance,
        iskBalance,
        expgramBalance,
      }}
    >
      {children}
    </IskraContext.Provider>
  );
};
