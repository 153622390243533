import React, { useEffect, useRef } from "react";
import styles from "./index.module.scss";
import cn from "classnames";
import { sleep } from "../../../../../utility/helpers";

import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { isMobile, isMobileWidth } from "../../../../../utility/helpers";

export const Partners = () => {
  const sectionRef = React.useRef(null);
  const partners = [
    [
      {
        src: require("../../../../../assets/images/homepage/partners/iskra_ci.png"),
        ref: useRef(null),
      },
      {
        src: require("../../../../../assets/images/homepage/partners/immutable_ci.png"),
        ref: useRef(null),
      },
      {
        src: require("../../../../../assets/images/homepage/partners/klaytn_ci.png"),
        ref: useRef(null),
      },
      {
        src: require("../../../../../assets/images/homepage/partners/havah_ci.png"),
        ref: useRef(null),
      },
    ],
    [
      {
        src: require("../../../../../assets/images/homepage/partners/smilegate_ci.png"),
        ref: useRef(null),
      },
      {
        src: require("../../../../../assets/images/homepage/partners/kaura_ci.png"),
        ref: useRef(null),
      },
      {
        src: require("../../../../../assets/images/homepage/partners/onestore_ci.png"),
        ref: useRef(null),
      },
      {
        src: require("../../../../../assets/images/homepage/partners/krust_ci.png"),
        ref: useRef(null),
      },
    ],
    [
      {
        src: require("../../../../../assets/images/homepage/partners/everyworld_ci.png"),
        ref: useRef(null),
      },
      {
        src: require("../../../../../assets/images/homepage/partners/aethir_ci.png"),
        ref: useRef(null),
      },
      {
        src: require("../../../../../assets/images/homepage/partners/c4c_ci.png"),
        ref: useRef(null),
      },
      {
        src: require("../../../../../assets/images/homepage/partners/com2s_ci.png"),
        ref: useRef(null),
      },
    ],
    [
      {
        src: require("../../../../../assets/images/homepage/partners/superwalk_ci.png"),
        ref: useRef(null),
      },
      {
        src: require("../../../../../assets/images/homepage/partners/perplay_ci.png"),
        ref: useRef(null),
      },
      {
        src: require("../../../../../assets/images/homepage/partners/ktown4u_ci.png"),
        ref: useRef(null),
      },
      {
        src: require("../../../../../assets/images/homepage/partners/wdo_ci.png"),
        ref: useRef(null),
      },
    ],
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            partners.forEach(async (row, rowIndex) => {
              row.forEach(async (partner, colIndex) => {
                if (isMobile || isMobileWidth()) {
                  await sleep(colIndex * 50);
                } else {
                  await sleep((3 - colIndex) * 100 + rowIndex * 50);
                }
                if (partner.ref.current) {
                  partner.ref.current.style.transform = "translateY(0)";
                }
              });
            });
          } else {
            partners.forEach(async (row) => {
              row.forEach(async (partner) => {
                if (partner.ref.current) {
                  partner.ref.current.style.transform = "translateX(-100vw)";
                }
              });
            });
          }
        });
      },
      {
        threshold: isMobile || isMobileWidth ? 0.2 : 0.5,
      }
    );

    observer.observe(sectionRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, []);

  return (
    <section className={styles.section} ref={sectionRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.title_box}>Backers & Partners</div>
        <div className={styles.partner_box}>
          {partners.map((row, index) => (
            <div className={styles.row} key={index}>
              {row.map((partner, index) => (
                <div className={styles.partner_col} key={index} ref={partner.ref}>
                  <img className={styles.partner} src={partner.src} alt="partner" />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
