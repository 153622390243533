import React, { useEffect, useState, useRef } from "react";
import styles from "./index.module.scss";
import cn from "classnames";

import { clearAllBodyScrollLocks } from "body-scroll-lock";

export const GameCard = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, []);

  return (
    <div className={cn(styles.card, props.className)}>
      <div className={styles.image_box}>
        <img src={props.data.src} alt="feature" />
      </div>
      <div className={styles.title_box}>{props.data.title}</div>
      <div className={styles.contents_box}>{props.data.text}</div>
    </div>
  );
};
