import React, { useEffect } from "react";
import styles from "./index.module.scss";
import cn from "classnames";

import { clearAllBodyScrollLocks } from "body-scroll-lock";

export const Economy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, []);

  return (
    <section className={styles.section}>
      <div className={cn("container", styles.container)}>
        <div className={styles.title_box}>$CANDY Economy</div>
        <div className={styles.subtitle_box}>Exchange the utility token $CANDY for $GRAM</div>
        <div className={styles.content_box}>
          <img className={styles.image} src={require("../../../../../assets/images/homepage/economy_candy.png")} alt="economy" />
        </div>
      </div>
    </section>
  );
};
