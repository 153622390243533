import React, { useEffect } from "react";
import styles from "./index.module.scss";
import cn from "classnames";

import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { GameCard } from "./card";

export const Introduce = () => {
  const expList = [
    {
      src: require("../../../../../assets/images/homepage/normaland_game1.png"),
      title: (
        <>
          Customize <br className="pc" /> your Chef
        </>
      ),
      text: <>Boost your cooking skills while looking top notch! Expend your culinary skills like never before!</>,
    },
    {
      src: require("../../../../../assets/images/homepage/normaland_game2.png"),
      title: (
        <>
          Upgrade <br className="pc" /> your Kitchen
        </>
      ),
      text: (
        <>
          Upgrade your gorgeous kitchen! The more you upgrade your
          <br />
          materials, cookware, and decorate your restaurant, the more popular it will become.
        </>
      ),
    },
    {
      src: require("../../../../../assets/images/homepage/normaland_game3.png"),
      title: (
        <>
          Cook and serve <br className="pc" /> 100+ recipes!
        </>
      ),
      text: <>Cook whatever your heart desires. Tacos, Ramen, BBQ, Pasta, Bibimbap or even donuts!</>,
    },
    {
      src: require("../../../../../assets/images/homepage/normaland_game4.png"),
      title: (
        <>
          World Chef <br className="pc" /> Championship
        </>
      ),
      text: <>Compete with chefs from around the world to win glory and prizes!</>,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, []);

  return (
    <section className={styles.section}>
      <div className={cn("container", styles.container)}>
        <div className={styles.title_box}>Cook and Earn! 🍳</div>
        <div className={styles.subtitle_box}>The most delicious game in the world.</div>
        <div className={styles.content_box}>
          {expList.map((exp, index) => (
            <GameCard className={styles.card} key={index} data={exp} />
          ))}
        </div>
      </div>
    </section>
  );
};
