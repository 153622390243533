import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Exchange.module.scss";
import { getShortenedBalance, displayBalanceFloat, converToWei, convertFromWei, sleep, displayBalanceWei } from "../../../../utility/helpers";

import { clearAllBodyScrollLocks } from "body-scroll-lock";
import Modal from "../../../elements/modal/Modal";
import { useIskra } from "../../../../module/hook/v1/hooks/useIskra";
import normaServerClient from "../../../../module/api/grampus/index";
import { Loading } from "../../../elements/loading/Loading";
export const Exchange = () => {
  const MINIMUM_EXCHANGE_RATE = 0.9;
  const { selectedAccount, getEstimatedChangePos, getEstimatedExchangeGasFee, exchangePos, candyBalance, iskBalance, updateCandyBalance, allowance, balanceOf, approve, userData, updateIskBalance, login } = useIskra();

  const [exchangeContractAddress, setExchangeContractAddress] = useState("");
  const [exchangePoolContractAddress, setExchangePoolContractAddress] = useState("");
  const [iskTokenAddress, setIskTokenAddress] = useState("");
  const [candyTokenAddress, setCandyTokenAddress] = useState("");

  const [exchangeRateIskToCandy, setExchangeRateIskToCandy] = useState(1);
  const [exchangeRateCandyToIsk, setExchangeRateCandyToIsk] = useState(1);
  const [isCandyToIsk, setIsCandyToIsk] = useState(true);
  const [sourceAmount, setSourceAmount] = useState(0);
  const [targetAmount, setTargetAmount] = useState(0);

  const [visibleModalChecking, setVisibleModalChecking] = useState(false);
  const [isTxProcessing, setIsTxProcessing] = useState(false);

  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContents, setModalContents] = useState("");

  const [exchangeTxFee, setExchangeTxFee] = useState(0);

  const [updateRemainCount, setUpdateRemainCount] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();

    normaServerClient
      .getContract("exchange")
      .then((response) => {
        setExchangeContractAddress(response.data.Address);
      })
      .catch((error) => {
        console.log(error);
      });

    normaServerClient
      .getContract("exchange_pool")
      .then((response) => {
        setExchangePoolContractAddress(response.data.Address);
      })
      .catch((error) => {
        console.log(error);
      });

    normaServerClient
      .getContract("isk")
      .then((response) => {
        setIskTokenAddress(response.data.Address);
      })
      .catch((error) => {
        console.log(error);
      });

    normaServerClient
      .getContract("candy")
      .then((response) => {
        setCandyTokenAddress(response.data.Address);
      })
      .catch((error) => {
        console.log(error);
      });

    updateExchangeRate();
  }, []);

  function switchCandyToIsk() {
    setIsCandyToIsk(!isCandyToIsk);
    setSourceAmount(0);
  }

  const updateExchangeRate = async () => {
    normaServerClient
      .getExchangeInfo()
      .then((res) => {
        const data = res.data;
        setExchangeRateIskToCandy(data.iskTokenEstimatedChangePos);
        setExchangeRateCandyToIsk(data.candyTokenEstimatedChangePos);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function onChangeSourceAmount(e) {
    setSourceAmount(Number(e.target.value));
  }

  useEffect(() => {
    setTargetAmount(sourceAmount * (isCandyToIsk ? exchangeRateCandyToIsk : exchangeRateIskToCandy));
  }, [sourceAmount, isCandyToIsk, exchangeRateIskToCandy, exchangeRateCandyToIsk]);

  function closeCheckingModal() {
    setVisibleModalChecking(false);
  }

  useEffect(() => {
    if (!selectedAccount || !candyTokenAddress || !iskTokenAddress || !selectedAccount || !sourceAmount) {
      return;
    }

    const inputTokenAddress = isCandyToIsk ? candyTokenAddress : iskTokenAddress;
    const inputAmount = converToWei(sourceAmount);
    const outputTokenAddress = isCandyToIsk ? iskTokenAddress : candyTokenAddress;
    const outputAmount = converToWei(sourceAmount * (isCandyToIsk ? exchangeRateCandyToIsk : exchangeRateIskToCandy) * MINIMUM_EXCHANGE_RATE);
    getEstimatedExchangeGasFee(selectedAccount, exchangeContractAddress, inputTokenAddress, inputAmount, outputTokenAddress, outputAmount)
      .then((response) => {
        setExchangeTxFee(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [sourceAmount, isCandyToIsk, candyTokenAddress, iskTokenAddress, selectedAccount, exchangeRateCandyToIsk, exchangeRateIskToCandy, exchangeContractAddress, getEstimatedExchangeGasFee]);

  function isInvalidSourceAmount() {
    if (sourceAmount <= 0) {
      return true;
    }

    if (isCandyToIsk) {
      return Number(sourceAmount) > Number(candyBalance);
    } else {
      return Number(sourceAmount) > Number(iskBalance);
    }
  }

  function finishTx(modalTitle, modalContents) {
    setIsTxProcessing(false);
    setVisibleModalChecking(false);
    setVisibleModalConfirm(true);
    setModalTitle(modalTitle);
    setModalContents(modalContents);
  }

  const runExchangeTx = async () => {
    setIsTxProcessing(true);

    const inputTokenAddress = isCandyToIsk ? candyTokenAddress : iskTokenAddress;
    const inputAmount = converToWei(sourceAmount);
    const outputTokenAddress = isCandyToIsk ? iskTokenAddress : candyTokenAddress;

    login()
      .then(() => {
        balanceOf(selectedAccount, isCandyToIsk ? candyTokenAddress : iskTokenAddress)
          .then((balanceWei) => {
            if (Number(convertFromWei(balanceWei)) < Number(sourceAmount)) {
              finishTx("Error", "Insufficient balance.");
              return;
            }

            allowance(selectedAccount, exchangeContractAddress, inputTokenAddress)
              .then(async (allowanceEth) => {
                if (Number(allowanceEth) < Number(sourceAmount) + 0.0001) {
                  try {
                    await approve(selectedAccount, exchangeContractAddress, inputTokenAddress, converToWei(Number(sourceAmount) - Number(allowanceEth) + 0.0001));
                    await sleep(1000);
                  } catch (e) {
                    finishTx("Error", e.message);
                    console.log(e);
                    return;
                  }
                }

                getEstimatedChangePos(selectedAccount, exchangePoolContractAddress, inputTokenAddress, inputAmount)
                  .then((estimatedOutputAmount) => {
                    const outputAmount = converToWei(Number(estimatedOutputAmount) * MINIMUM_EXCHANGE_RATE);

                    exchangePos(selectedAccount, exchangeContractAddress, inputTokenAddress, inputAmount, outputTokenAddress, outputAmount)
                      .then((txHash) => {
                        normaServerClient.addExchangeItem(userData?.accessToken, isCandyToIsk ? 1 : 0, txHash, exchangeContractAddress);
                        finishTx("Success", "The exchange was successful.");
                        resetStatus();
                      })
                      .catch((error) => {
                        console.log(error);
                        finishTx("Error", error.message);
                      });
                  })
                  .catch((error) => {
                    console.log(error);
                    finishTx("Error", error.message);
                  });
              })
              .catch((error) => {
                console.log(error);
                finishTx("Error", error.message);
              });
          })
          .catch((error) => {
            console.log(error);
            finishTx("Error", error.message);
          });
      })
      .catch((error) => {
        console.log(error);
        finishTx("Error", error.message);
      });
  };

  function resetStatus() {
    setUpdateRemainCount(10);
    setSourceAmount(0);
    setTargetAmount(0);
  }

  useEffect(() => {
    if (updateRemainCount > 0) {
      setTimeout(() => {
        updateIskBalance(selectedAccount);
        updateCandyBalance(selectedAccount);
        updateExchangeRate();

        setUpdateRemainCount(updateRemainCount - 1);
      }, 1000);
    }
  }, [updateRemainCount, selectedAccount, updateIskBalance, updateCandyBalance]);

  return (
    <main className={styles.main}>
      <section className={styles.section}>
        <div className={styles.background}>
          <img className={styles.pc_img} src={require("../../../../assets/images/homepage/exchange_bg.png")} alt="bg" />
          <img className={cn(styles.mobile_img, styles.mobile_img_1)} src={require("../../../../assets/images/homepage/exchange_mobile_bg1.png")} alt="bg" />
          <img className={cn(styles.mobile_img, styles.mobile_img_2)} src={require("../../../../assets/images/homepage/exchange_mobile_bg2.png")} alt="bg" />
          <img className={cn(styles.mobile_img, styles.mobile_img_3)} src={require("../../../../assets/images/homepage/exchange_mobile_bg3.png")} alt="bg" />
        </div>
        <div className={cn("container", styles.container)}>
          <div className={styles.title}>$ISK Exchange</div>
          <div className={styles.content}>
            Exchange CANDY you obtained from 'Norma in Metaland' for $ISK Tokens.
            <br />
            $ISK tokens can be swapped with KLAY, ISK, oUSDT, etc. in Iskra DEX.
          </div>
          <div className={styles.exchange_info_container}>
            <div className={styles.exchange_rate}>
              <div className={styles.exchange_rate_title}>Exchange Rate</div>
              <div className={styles.exchange_rate_content}>
                {isCandyToIsk ? (
                  <>
                    <span>1</span> CANDY <span>: {getShortenedBalance(exchangeRateCandyToIsk)}</span> ISK
                  </>
                ) : (
                  <>
                    <span>1</span> ISK <span>: {getShortenedBalance(exchangeRateIskToCandy)}</span> CANDY
                  </>
                )}
              </div>
            </div>
            <div className={styles.volume}>Balance : {isCandyToIsk ? displayBalanceFloat(candyBalance) : displayBalanceFloat(iskBalance)}</div>
            <div className={styles.token_info_container}>
              <div className={cn(styles.row, styles.from)}>
                <div className={cn(styles.col, styles.from)}>From</div>

                <div className={cn(styles.col, styles.button, styles.mobile_button)}>
                  <button className={cn("button", styles.max_button)} onClick={() => (isCandyToIsk ? setSourceAmount(candyBalance) : setSourceAmount(iskBalance))}>
                    MAX
                  </button>
                </div>
              </div>
              <div className={styles.row}>
                <div className={cn(styles.col, styles.icon)}>{isCandyToIsk ? <img src={require("../../../../assets/images/homepage/candy_icon.png")} alt="candy" /> : <img src={require("../../../../assets/images/homepage/iskra_icon.png")} alt="iskra" />}</div>
                <div className={cn(styles.col, styles.symbol)}>{isCandyToIsk ? "CANDY" : "ISK"}</div>
                <div className={cn(styles.col, styles.amount)}>
                  <input type="number" value={sourceAmount ? getShortenedBalance(sourceAmount) : ""} onChange={onChangeSourceAmount} placeholder="0.0000" />
                </div>
                <div className={cn(styles.col, styles.button)}>
                  <button className={cn("button", styles.max_button)} onClick={() => (isCandyToIsk ? setSourceAmount(candyBalance) : setSourceAmount(iskBalance))}>
                    MAX
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.change_button_container}>
              <button className={cn(styles.change_button)} onClick={() => switchCandyToIsk()}>
                <img src={require("../../../../assets/images/homepage/change.png")} alt="change" />
              </button>
            </div>
            <div className={styles.volume}>Balance : {isCandyToIsk ? displayBalanceFloat(iskBalance) : displayBalanceFloat(candyBalance)}</div>
            <div className={styles.token_info_container}>
              <div className={cn(styles.row)}>
                <div className={cn(styles.col, styles.from)}>To</div>
              </div>
              <div className={styles.row}>
                <div className={cn(styles.col, styles.icon)}>{isCandyToIsk ? <img src={require("../../../../assets/images/homepage/iskra_icon.png")} alt="iskra" /> : <img src={require("../../../../assets/images/homepage/candy_icon.png")} alt="candy" />} </div>
                <div className={cn(styles.col, styles.symbol)}>{isCandyToIsk ? "ISK" : "CANDY"}</div>
                <div className={cn(styles.col, styles.amount)}>{getShortenedBalance(targetAmount)}</div>
                <div className={cn(styles.col, styles.button)}></div>
              </div>
            </div>
            <div className={styles.exchange_button_container}>
              <button className={cn("button", styles.exchange_button, { [styles.disabled]: isInvalidSourceAmount() })} onClick={() => setVisibleModalChecking(true)}>
                Exchange
              </button>
            </div>
          </div>
          <div className={styles.exp}>
            ※ The exchange rate is set based on the ratio between in-game Candy token and exchangeable ISK token in real time. <br />※ The exchange fee is 10% of total volume of exchange.
          </div>
        </div>
      </section>

      <Modal outerClassName={styles.check_modal} visible={visibleModalChecking} onClose={closeCheckingModal} unquenchable={isTxProcessing}>
        <div className={styles.check_title}>Check</div>
        <div className={styles.check_token_info_container}>
          <div className={cn(styles.row, styles.from)}>From</div>
          <div className={styles.row}>
            <div className={cn(styles.col, styles.icon)}>{isCandyToIsk ? <img src={require("../../../../assets/images/homepage/candy_icon.png")} alt="candy" /> : <img src={require("../../../../assets/images/homepage/iskra_icon.png")} alt="iskra" />}</div>
            <div className={styles.mobile_row}>
              <div className={cn(styles.col, styles.symbol)}>{isCandyToIsk ? "CANDY" : "ISK"}</div>
              <div className={cn(styles.col, styles.amount)}>{displayBalanceFloat(sourceAmount)}</div>
            </div>
          </div>
        </div>
        <div className={styles.check_change_button_container}>
          <img src={require("../../../../assets/images/homepage/check_change.png")} alt="change" />
        </div>
        <div className={styles.check_token_info_container}>
          <div className={cn(styles.row, styles.from)}>To</div>
          <div className={styles.row}>
            <div className={cn(styles.col, styles.icon)}>{isCandyToIsk ? <img src={require("../../../../assets/images/homepage/iskra_icon.png")} alt="iskra" /> : <img src={require("../../../../assets/images/homepage/candy_icon.png")} alt="candy" />}</div>
            <div className={styles.mobile_row}>
              <div className={cn(styles.col, styles.symbol)}>{isCandyToIsk ? "ISK" : "CANDY"}</div>
              <div className={cn(styles.col, styles.amount)}>{displayBalanceFloat(targetAmount)}</div>
            </div>
          </div>
        </div>
        <div className={styles.check_token_more_info_container}>
          <div className={styles.check_token_more_info_row}>
            <span className={styles.check_token_more_info_label}>Exchange Rate</span>
            <span className={styles.check_token_more_info_value}>{isCandyToIsk ? "1 CANDY = " + displayBalanceFloat(exchangeRateCandyToIsk) + " ISK" : "1 ISK = " + displayBalanceFloat(exchangeRateIskToCandy) + " CANDY"}</span>
          </div>
          <div className={styles.check_token_more_info_row}>
            <span className={styles.check_token_more_info_label}>Estimatied Transaction Fee</span>
            <span className={styles.check_token_more_info_value}>
              <img className={styles.icon} src={require("../../../../assets/images/homepage/staking_klaytn_icon.png")} alt="klaytn" />
              <span className={styles.value}>{displayBalanceWei(exchangeTxFee)} KLAY</span>
            </span>
          </div>
        </div>
        <div className={styles.check_exchange_button_container}>
          <button
            className={cn("button", styles.check_exchange_button, { [styles.disabled]: isTxProcessing })}
            onClick={() => {
              runExchangeTx();
            }}
          >
            {isTxProcessing ? <Loading /> : "Exchange"}
          </button>
        </div>
      </Modal>
      <Modal outerClassName={styles.confirm_modal} visible={visibleModalConfirm} unquenchable={false} onClose={() => setVisibleModalConfirm(false)}>
        <div className={styles.modal_content}>
          <div className={styles.modal_title}>{modalTitle}</div>
          <div className={styles.modal_content_box}>
            <div className={styles.modal_contents}>{modalContents}</div>
          </div>
          <div className={styles.modal_btn_container}>
            <button className={cn(styles.modal_btn, { [styles.error]: modalTitle === "Error" })} onClick={() => setVisibleModalConfirm(false)}>
              OK
            </button>
          </div>
        </div>
      </Modal>
    </main>
  );
};
