import React from "react";
import styles from "./WaterMark.module.scss";

export const WaterMark = () => {
  return (
    <div className={styles.watermark_container}>
      {process.env.REACT_APP_ENV != "production" && (
        <>
          <div className={styles.watermark_lt}>{process.env.REACT_APP_ENV}</div>
          <div className={styles.watermark_rb}>{process.env.REACT_APP_ENV}</div>
        </>
      )}
    </div>
  );
};
