// set local storage
export function setLocalStorage(key, value) {
  localStorage.setItem(key, value);
}

// reset local storage
export function resetLocalStorage(key) {
  localStorage.removeItem(key);
}

// get local storage
export function getLocalStorage(key) {
  return localStorage.getItem(key);
}
