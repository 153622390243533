import React, { useEffect } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Header } from "./components/header/v2";
import { Footer } from "./components/footer/v2";
import { Providers } from "./providers";
import { Main as LandingMain } from "./components/pages/v2/landing";
import { Main as NormalandMain } from "./components/pages/v2/normaland";
import { Main as OcsMain } from "./components/pages/v2/ocs";
import { WaterMark } from "./components/elements/watermark/WaterMark";
import { Exchange } from "./components/pages/v1/exchange/Exchange";
import { Staking } from "./components/pages/v1/staking/Staking";
import { UpArrow } from "./components/elements/up_arrow/UpArrow";

function App() {
  return (
    <div className="App">
      <BrowserRouter basename="/">
        <Providers>
          <Header />
          <Routes>
            <Route path="/" element={<LandingMain />}></Route>
            <Route path="/staking" element={<Staking />}></Route>
            <Route path="/exchange/candy" element={<Exchange />}></Route>
            <Route path="/game/normaland" element={<NormalandMain />}></Route>
            <Route path="/game/juicyadventure" element={<OcsMain />}></Route>
          </Routes>
          <Footer />
          <WaterMark />
          <UpArrow className="mobile" />
        </Providers>
      </BrowserRouter>
    </div>
  );
}

export default App;
