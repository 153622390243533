import { createContext, useContext } from 'react';

export const MetamaskContext = createContext({
    connectWallet: () => { },
    disconnectWallet: () => { },
    selectedAccount: "",
    error: "",
    loading: false,
});

export function useMetamask() {
    return useContext(MetamaskContext);
}
