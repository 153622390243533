import React, { useEffect } from "react";
import styles from "./index.module.scss";
import { Banner } from "./banner";
import { Powered } from "./powered";
import { Ecosystem } from "./ecosystem";
import { Nfts } from "./nfts";
import { Grampus } from "./grampus";
import { Partners } from "./partners";
import { Separator } from "../../../elements/separator";

import { clearAllBodyScrollLocks } from "body-scroll-lock";

export const Main = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, []);

  return (
    <main className={styles.main}>
      <Banner />
      <Powered />
      <Separator />
      <Ecosystem />
      <Separator />
      <Nfts />
      <Separator />
      <Grampus />
      <Separator />
      <Partners />
    </main>
  );
};
