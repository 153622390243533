import { createContext, useContext } from "react";
import React, { useEffect, useState } from "react";
import { createAuthenticationAdapter, getDefaultConfig } from "@rainbow-me/rainbowkit";

import { base, sepolia } from "wagmi/chains";
import { isTest } from "../../../utility/helpers";
import { http } from "wagmi";
import { SiweMessage } from "siwe";
import grampusClient from "../../api/grampus";
import { setLocalStorage, resetLocalStorage } from "../../storage";

export const STATE = {
  LOADING: "loading",
  UNAUTHENTICATED: "unauthenticated",
  AUTHENTICATED: "authenticated",
};

let setState = () => {};

export function setCallback(callback) {
  setState = callback;
}

export const authenticationAdapter = createAuthenticationAdapter({
  getNonce: async () => {
    const ret = await grampusClient.getNonce();
    return ret?.data?.nonce;
  },

  createMessage: ({ nonce, address, chainId }) => {
    return new SiweMessage({
      domain: window.location.host,
      address,
      statement: "Sign in Grampus.",
      uri: window.location.origin,
      version: "1",
      chainId,
      nonce,
    });
  },

  getMessageBody: ({ message }) => {
    return message.prepareMessage();
  },

  verify: async ({ message, signature }) => {
    const ret = await grampusClient.verifySignature(message, signature);

    const success = ret?.data?.verified;

    if (!success) {
      return Boolean(false);
    }

    const token = ret?.data?.token;
    // set token to local storage

    setLocalStorage("token", token);

    setState(STATE.AUTHENTICATED);
    return Boolean(true);
  },

  signOut: async () => {
    resetLocalStorage("token");

    setState(STATE.UNAUTHENTICATED);
  },
});

export const wagmiConfig = getDefaultConfig({
  appName: process.env.REACT_APP_WAGMI_APP_NAME,
  projectId: process.env.REACT_APP_WAGMI_APP_ID,
  chains: isTest ? [sepolia] : [base],
  ssr: false, // If your dApp uses server side rendering (SSR)
  transports: isTest
    ? {
        [sepolia.id]: http(process.env.REACT_APP_WAGMI_APP_BASE_PRODIVER_URL),
      }
    : {
        [base.id]: http(process.env.REACT_APP_WAGMI_APP_BASE_PRODIVER_URL),
      },
});
