import React, { useState } from "react";
import styles from "./index.module.scss";
import cn from "classnames";
import { LinkButton } from "../../../../elements/linkbutton";

export const Nft = () => {
  return (
    <section className={styles.section}>
      <div className={cn("container", styles.container)}>
        <div className={styles.character_box}>
          <img src={require("../../../../../assets/images/homepage/juicy_nft_character.png")} alt="character" />
        </div>
        <div className={styles.contents_box}>
          <div className={styles.title_box}>Shooter and Skin NFTS</div>
          <div className={styles.subtitle_box}>
            Collect limited edition shooters
            <br />
            and skins to top the leaderboard
            <br />
            in $JELLY battles!
          </div>
          <div className={styles.button_box}>
            <LinkButton className={cn(styles.button, styles.disabled)} link="/nft">
              BUY
            </LinkButton>
            <LinkButton className={cn(styles.opensea_button, styles.disabled)} link="/nft">
              <img src={require("../../../../../assets/images/homepage/opensea.png")} alt="arrow" />
            </LinkButton>
          </div>
        </div>
      </div>
    </section>
  );
};
