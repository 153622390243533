import { createContext, useEffect } from "react";
import { toast } from "react-toastify";

const ErrorToastContext = createContext(null);

const options = {
  position: toast.POSITION.TOP_LEFT,
  autoClose: false,
  closeOnClick: true,
};

export const ErrorToastProvider = ({ children }) => {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      return;
    }
    // 전역 에러 핸들링 설정
    window.onerror = (message, source, lineno, colno, error) => {
      toast.error(message, options);
    };

    window.addEventListener("unhandledrejection", (event) => {
      toast.error(event.reason.stack, options);
    });
  }, []);

  return <ErrorToastContext.Provider value={null}>{children}</ErrorToastContext.Provider>;
};
