import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import cn from "classnames";
import { LinkButton } from "../../../../elements/linkbutton";
import { isMobile, isMobileWidth } from "../../../../../utility/helpers";

export const Banner = () => {
  const sectionRef = useRef(null);
  const splatRef = useRef(null);
  const logoRef = useRef(null);

  const splatMoveFactor = 50;
  const logoMoveFactor = 10;

  useEffect(() => {
    if (!sectionRef.current || !splatRef.current || !logoRef.current || isMobile || isMobileWidth()) {
      return;
    }

    sectionRef.current.addEventListener("mousemove", (e) => {
      // 센터에서부터 마우스 위치에 따라 이동
      const x = e.clientX;
      const y = e.clientY;

      const diffX = sectionRef.current.offsetWidth / 2 - x;
      const diffY = sectionRef.current.offsetHeight / 2 - y;

      splatRef.current.style.transform = `translate(${diffX / splatMoveFactor}px, ${diffY / splatMoveFactor}px)`; // splatRef의 위치 변경
      logoRef.current.style.transform = `translate(${diffX / logoMoveFactor}px, ${diffY / logoMoveFactor}px)`; // logoRef의 위치 변경
    });

    return () => {
      if (sectionRef.current) sectionRef.current.removeEventListener("mousemove", (e) => {});
    };
  }, [sectionRef, splatRef, logoRef]);
  return (
    <section className={styles.section} ref={sectionRef}>
      <div className={styles.background_box}>
        <img className={cn(styles.background_img)} src={require("../../../../../assets/images/homepage/juicy_banner_bg.png")} alt="background" />
      </div>
      <div className={cn("container", styles.container)}>
        <div className={styles.splat_box}>
          <img src={require("../../../../../assets/images/homepage/juicy_banner_splat.png")} alt="splat" ref={splatRef} />
        </div>
        <div className={styles.logo_box}>
          <img src={require("../../../../../assets/images/homepage/juicy_banner_logo.png")} alt="logo" ref={logoRef} />
        </div>
        <div className={styles.content_box}>
          Don’t miss out on super bullish events,
          <br />
          including the reveal of Game details,
          <br />
          CBT and NFT launches!
        </div>
        <div className={styles.button_box}>
          <LinkButton className={cn(styles.button)} link="https://x.com/juicy_adventure">
            Go to <img src={require("../../../../../assets/images/homepage/x_icon_reverse.png")} alt="x" />
          </LinkButton>
        </div>
      </div>
    </section>
  );
};
