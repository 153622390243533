import { createContext, useContext } from 'react';

export const KaikasContext = createContext({
    connectWallet: () => { },
    disconnectWallet: () => { },
    selectedAccount: "",
    loading: false,
});

export function useKaikas() {
    return useContext(KaikasContext);
}
