import React from "react";
import cn from "classnames";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";

export const LinkButton = ({ className, link, isBlank = false, children, onClick }) => {
  const [isDisabled, setDisabled] = React.useState(false);

  React.useEffect(() => {
    setDisabled(className?.includes("disabled"));
  }, [className]);

  return (
    <>
      {isDisabled ? (
        <span className={cn(styles.button, className)} aria-disabled="true">
          {children}
        </span>
      ) : (
        <>
          {link?.startsWith("/") ? (
            <Link className={cn(styles.button, className)} to={link} onClick={onClick}>
              {children}
            </Link>
          ) : (
            <a className={cn(styles.button, className)} href={link} target={isBlank ? "_blank" : ""} rel="noreferrer" onClick={onClick}>
              {children}
            </a>
          )}
        </>
      )}
    </>
  );
};
