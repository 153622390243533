import React, { useEffect } from "react";
import styles from "./index.module.scss";
import cn from "classnames";
import { GameCard } from "./card";
import { LinkButton } from "../../../../elements/linkbutton";

import { clearAllBodyScrollLocks } from "body-scroll-lock";

export const Powered = () => {
  const sectionRef = React.useRef(null);

  const games = [
    {
      img: require("../../../../../assets/images/homepage/norma_game_card.png"),
      title: `"Master Recipes and Manage Restaurant!"`,
      name: "Norma in Metaland",
      link: "/game/normaland",
    },
    {
      img: require("../../../../../assets/images/homepage/juicy_game_card.png"),
      title: `"Shoot, Survive, and Earn! 5v5 Casual Shooter"`,
      name: "Juicy Adventure",
      link: "/game/juicyadventure",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, []);

  return (
    <section className={styles.section} ref={sectionRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.title_box}>Powerd by $GRAM</div>
        <div className={styles.subtitle_box}>Experience a new level of gaming enhanced by the $GRAM Ecosystem.</div>
        <div className={styles.cards_box}>
          {games.map((game, index) => (
            <LinkButton className={styles.card} key={index} link={game.link}>
              <GameCard data={game} />
            </LinkButton>
          ))}
        </div>
      </div>
    </section>
  );
};
