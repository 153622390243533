import React, { useEffect } from "react";
import styles from "./index.module.scss";
import cn from "classnames";

export const Separator = () => {
  return (
    <section className={styles.section}>
      <div className={cn("container", styles.conatiner, "pc")}></div>
    </section>
  );
};
