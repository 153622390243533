import React, { useEffect, useState } from "react";
import cn from "classnames";
import copy from "copy-to-clipboard";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.scss";
import { Link } from "react-router-dom";
import Icon from "../../Icon";
import { getShortenedAddress, displayBalanceFloat } from "../../../utility/helpers";
import Popup from "reactjs-popup";
import { useIskra } from "../../../module/hook/v1/hooks/useIskra";

export const User = ({ className }) => {
  const { login, logout, requestAccountsFromIskra, gramBalance, sgramBalance, candyBalance, userData, expgramBalance, selectedAccount, iskBalance } = useIskra();

  const [visible, setVisible] = useState(false);

  const [tokenInfoList, setTokenInfoList] = useState([]);

  useEffect(() => {
    setTokenInfoList([
      {
        title: "GRAM Token Balance",
        name: "GRAM",
        alt: "gram",
        icon: require("../../../assets/images/gram_icon.png"),
        balance: gramBalance,
        hide_zero: false,
      },
      {
        title: "GRAM Staking Token Balance",
        name: "sGRAM",
        alt: "sgram",
        icon: require("../../../assets/images/sgram_icon.png"),
        balance: sgramBalance,
        hide_zero: false,
      },
      {
        title: "Express GRAM Token Balance",
        name: "expGRAM",
        alt: "expgram",
        icon: require("../../../assets/images/exp_gram icon.png"),
        balance: expgramBalance,
        hide_zero: false,
      },
      {
        title: "CANDY Token Balance",
        name: "CANDY",
        alt: "candy",
        icon: require("../../../assets/images/candy_icon.png"),
        balance: candyBalance,
        hide_zero: false,
      },
      {
        title: "ISKRA Token Balance",
        name: "ISK",
        alt: "iskra",
        icon: require("../../../assets/images/iskra_icon.png"),
        balance: iskBalance,
        hide_zero: false,
      },
    ]);
  }, [gramBalance, sgramBalance, candyBalance, iskBalance, expgramBalance]);

  useEffect(() => {
    if (userData && !selectedAccount) {
      requestAccountsFromIskra();
    }
  }, [userData, selectedAccount, requestAccountsFromIskra]);

  const onLoginButtonClicked = () => {
    login().catch(showError);
  };

  const onLogoutButtonClicked = () => {
    logout();
    setVisible(false);
  };

  const showError = (error) => {
    alert(error);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.user, className)}>
        {!userData && (
          <div className={styles.login_box}>
            <button className={cn(styles.login_button)} onClick={() => onLoginButtonClicked()}>
              <span>Sign in</span>
            </button>
          </div>
        )}
        {userData && (
          <div className={styles.login_box}>
            <button className={cn(styles.login_button)} onClick={() => setVisible(!visible)}>
              <span>My Profile</span>
            </button>
          </div>
        )}
        {userData && visible && (
          <div className={styles.outer}>
            <div className={styles.body}>
              <div className={styles.name}>{userData.email}</div>
              {userData && (
                <div className={styles.code}>
                  <div className={styles.number}>{getShortenedAddress(selectedAccount)}</div>
                  <Popup
                    onOpen={() => copy(selectedAccount)}
                    trigger={
                      <button className={styles.copy}>
                        <Icon name="copy" size="16" />
                      </button>
                    }
                    position="top center"
                    closeOnDocumentClick
                  >
                    Copied!
                  </Popup>
                </div>
              )}
              <div className={styles.wrap}>
                {userData &&
                  tokenInfoList
                    .filter((tokenInfo) => !tokenInfo.hide_zero || tokenInfo.balance > 0)
                    .map((tokenInfo, index) => {
                      return (
                        <div className={styles.line} key={index}>
                          <div className={styles.info}>{tokenInfo.title}</div>
                          <div className={styles.details}>
                            <div className={styles.preview}>
                              <img src={tokenInfo.icon} alt={tokenInfo.alt} />
                            </div>
                            <div className={styles.price_container}>
                              <div className={styles.name}>{tokenInfo.name}</div>
                              <div className={styles.price}>{displayBalanceFloat(tokenInfo.balance)}</div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
              <div className={styles.menu}>
                <Link className={styles.item} onClick={() => onLogoutButtonClicked()} key="1">
                  <div className={styles.icon}>
                    <Icon name="exit" size="20" />
                  </div>
                  <div className={styles.text}>Disconnect</div>
                </Link>
              </div>
              <div className={styles.close_btn_container}>
                <button className={styles.close_btn} onClick={() => setVisible(false)}>
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};
