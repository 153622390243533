import React, { useEffect, useRef } from "react";
import styles from "./index.module.scss";
import cn from "classnames";
import { sleep } from "../../../../../utility/helpers";

import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { LinkButton } from "../../../../elements/linkbutton";
import { isMobile, isMobileWidth } from "../../../../../utility/helpers";

export const Grampus = () => {
  const imgList = [
    [
      {
        src: require("../../../../../assets/images/homepage/grampus_dolphin_1.png"),
        ref: useRef(null),
      },
      {
        src: require("../../../../../assets/images/homepage/grampus_dolphin_2.png"),
        ref: useRef(null),
      },
      {
        src: require("../../../../../assets/images/homepage/grampus_dolphin_3.png"),
        ref: useRef(null),
      },
      {
        src: require("../../../../../assets/images/homepage/grampus_dolphin_4.png"),
        ref: useRef(null),
      },
    ],
    [
      {
        src: require("../../../../../assets/images/homepage/grampus_dolphin_5.png"),
        ref: useRef(null),
      },
      {
        src: require("../../../../../assets/images/homepage/grampus_dolphin_6.png"),
        ref: useRef(null),
      },
      {
        src: require("../../../../../assets/images/homepage/grampus_dolphin_7.png"),
        ref: useRef(null),
      },
      {
        src: require("../../../../../assets/images/homepage/grampus_dolphin_8.png"),
        ref: useRef(null),
      },
    ],
    [
      {
        src: require("../../../../../assets/images/homepage/grampus_dolphin_9.png"),
        ref: useRef(null),
      },
      {
        src: require("../../../../../assets/images/homepage/grampus_dolphin_10.png"),
        ref: useRef(null),
      },
      {
        src: require("../../../../../assets/images/homepage/grampus_dolphin_11.png"),
        ref: useRef(null),
      },
      {
        src: require("../../../../../assets/images/homepage/grampus_dolphin_12.png"),
        ref: useRef(null),
      },
    ],
    [
      {
        src: require("../../../../../assets/images/homepage/grampus_dolphin_13.png"),
        ref: useRef(null),
      },
      {
        src: require("../../../../../assets/images/homepage/grampus_dolphin_14.png"),
        ref: useRef(null),
      },
      {
        src: require("../../../../../assets/images/homepage/grampus_dolphin_15.png"),
        ref: useRef(null),
      },
      {
        src: require("../../../../../assets/images/homepage/grampus_dolphin_16.png"),
        ref: useRef(null),
      },
    ],
  ];

  const sectionRef = React.useRef(null);

  // 현재 스크롤이 sectionRef에 닿으면 imgList의 모든 ref의 opacity를 1로 변경하고 translateY(0)으로 변경합니다.
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          imgList.forEach((imgCol, colIndex) => {
            imgCol.forEach(async (img, rowIndex) => {
              if (entry.isIntersecting) {
                await sleep(colIndex * 150 + rowIndex * 200 + 300);
                img.ref.current.style.opacity = 1;
                img.ref.current.style.transform = "translateY(0)";
              } else {
                img.ref.current.style.opacity = 0;
                img.ref.current.style.transform = "translateY(30px)";
              }
            });
          });
        });
      },
      {
        threshold: isMobile || isMobileWidth() ? 0.3 : 0.5,
      }
    );

    observer.observe(sectionRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, []);

  return (
    <section className={styles.section} ref={sectionRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.left_box}>
          {imgList.map((imgCol, rowIndex) => (
            <div className={styles.img_col} key={rowIndex}>
              {imgCol.map((img, colIndex) => (
                <img className={styles.img} key={colIndex} src={img.src} alt="img" ref={img.ref} />
              ))}
            </div>
          ))}
        </div>
        <div className={styles.right_box}>
          <div className={styles.logo_box}>
            <img className={styles.logo} src={require("../../../../../assets/images/homepage/grampus_ci.png")} alt="ci" />
          </div>
          <div className={styles.contents_box}>
            GRAMPUS CWC, an initiative builder
            <br />
            of the $GRAM Ecosystem,
            <br />
            is the blockchain-specialized subsidiary
            <br />
            of the Korean global multi-platform
            <br />
            game developer/publisher GRAMPUS.
          </div>
          <div className={styles.button_box}>
            <LinkButton className={styles.button} link="https://grampus.co/">
              Learn More
            </LinkButton>
          </div>
        </div>
      </div>
    </section>
  );
};
