import Web3 from "web3";
import BigNumber from "bignumber.js";

export const convertFromHexDecimal = (hex) => parseInt(hex, 16) * 0.000000000000000001;

export const convertFromWei = (number) => {
  if (!number) {
    return 0;
  }
  if (typeof number !== "string") {
    // convert number to string
    number = number.toString();
  }
  return Web3.utils.fromWei(number, "ether");
};
export const converToWei = (number) => {
  if (!number) {
    return 0;
  }
  if (typeof number !== "string") {
    // convert number to string
    number = number.toString();
  }

  return Web3.utils.toWei(number, "ether");
};

export const getShortenedAddress = (address) => {
  if (!address || address.length < 10) {
    return address;
  }
  return `${address.slice(0, 6)}...${address.slice(address.length - 6)}`;
};

export const getShortenedTx = (tx) => {
  if (!tx || tx.length < 24) {
    return tx;
  }
  return `${tx.slice(0, 10)}...${tx.slice(tx.length - 10)}`;
};

export const getShortenedBalance = (balance) => {
  return Math.round(balance * 10000) / 10000;
};

export const getShortenedBalanceDigit = (balance, digit) => {
  return Math.round(balance * Math.pow(10, digit)) / Math.pow(10, digit);
};

export const dollarsToCoin = (dollars) => {
  return Math.round((dollars / 200) * 1000) / 1000;
};
export const coinToDollars = (coin) => {
  return Math.round(coin * 200 * 1000) / 1000;
};

export const numberWithCommas = (x) => {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const displayBalanceFloat = (number) => numberWithCommas(getShortenedBalance(number));

export const displayBalanceWei = (number) => numberWithCommas(getShortenedBalance(convertFromWei(number)));

export const isVideoOrAudioUrl = (url) => url && ["mp4", "mov", "webm", "mp3", "wav", "ogg"].includes(url.split(".").pop());

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const encodeAddress = (account) => {
  return `${account.toLowerCase().replace("0x", "").padStart(64, "0")}`;
};

export const encodeUint256 = (string) => {
  return `${Web3.utils.toHex(string).replace("0x", "").padStart(64, "0")}`;
};

export const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

export const isMobileWidth = () => window.innerWidth < 1179;

export const isTest = process.env.REACT_APP_ENV === "local" || process.env.REACT_APP_ENV === "test" || process.env.REACT_APP_ENV === "development";

const getRandomHex = (length) => {
  let result = "";
  const characters = "0123456789abcdef";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export const getRandomNonce = (len) => {
  while (true) {
    const nonce = getRandomHex(len - 1);
    const nonceBigNumber = BigNumber(`0x${nonce}`);
    if (nonceBigNumber.mod(process.env.REACT_APP_NONCE_MOD).isZero()) {
      return nonce + getRandomHex(1);
    }
  }
};
