import React, { useEffect } from "react";
import styles from "./index.module.scss";

import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { Movie } from "./movie";
import { Banner } from "./banner";
import { Introduce } from "./introduce";
import { Screenshot } from "./screenshot";
import { Economy } from "./economy";
import { Separator } from "../../../elements/separator";

export const Main = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, []);

  return (
    <main className={styles.main}>
      <Movie />
      <Banner />
      <Separator />
      <Introduce />
      <Separator />
      <Screenshot />
      <Separator />
      <Economy />
    </main>
  );
};
